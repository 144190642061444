import { useEffect, useState } from "react";
import * as utils from "../services/utilities";
import { makeStyles } from "@mui/styles";
import PlanService from "../services/plan";
import PricingCard from "../components/PricingCard";

export const Pricing = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  // const {employee} = useAuth0();
  const fetchPricing = async () => {
    try {
      setIsLoading(true);
      const plans = await new PlanService().getSubscriptionPlans();

      const plansArr = plans?.map((items, index) => {
        return {
          heading: items?.code || "Starter",
          description:
            items?.description ||
            "Get your starter plan for getting small scale services.",
          price: `$${items?.price}`,
          btnText: items?.code === isLoading ? "Please Wait" : "Choose Plan",
          services: [
            { service: `${items?.surveyMax.toLocaleString() || 0} surveys` },
            {
              service: `${
                items?.coordinatorMax.toLocaleString() || 0
              } coordinators`,
            },
            {
              service: `${
                items?.questionMax.toLocaleString() || 0
              } Max-Question`,
            },
            /*             {
              service: `${
                items?.dataRetentionDays || 0
              } > Data Retentions Days`,
            }, */
            {
              service: `${
                items?.surveyRecipientMax.toLocaleString() || 0
              } Survey Recipient Max`,
            },
            /*             {
              service: `${items?.reportsIncluded || 0} Reports Included`,
            }, */
            /*             { service: `${items?.dataRetentionDays || 0} day data retention` }, */
            /*             {
              service: items?.enableLiveResults
                ? "Live Results"
                : "Live Results (Not Included)",
            },
            {
              service: items?.includeLayouts
                ? "Include Layouts"
                : "Layouts (Not Included)",
            }, */
            /*             {
              service: items?.reportsIncluded
                ? "Reports Included"
                : "Reports (Not Included)",
            }, */
          ],
        };
      });

      setPricingPlans(plansArr);
    } catch (error) {
      utils.log.error("Error fetching in pricing plans:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  if (pricingPlans?.length === 0) {
    return <>- No Pricing plans found. -</>;
  }

  const handleClick = async (item) => {
    utils.log.info(":: Info",{item})
    // if(employee){
    //   const response = await new PlanService.chooseSubscriptionPlans(payload);
    // }else{
    //   const response = await  new PlanService.choosePlan()
    // }
  };

  return (
    <>
      <div className={classes.wrapper}>
          <div>
            <PricingCard
              pricingArr={pricingPlans}
              showAd={false}
              handleClick={handleClick}
            />
          </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "200px",
    zIndex: 1,
    // background: "#f5f5f5",
    position: "relative",
  },
}));
