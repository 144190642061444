import React, { useContext, useState, useMemo, useEffect } from "react";
import { useSnackbar } from "notistack";
import Grid from "../../components/Grid";
import RenderForm from "../../components/Form/RenderForm";
import Button from "../../components/Button";
import { Stack } from "@mui/material";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { SideBar } from "../../components/SideBar";
import { PROFILE_PAGE_TAB_LINKS } from "../../utils/sideBarData";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../../App";
import DashboardLayout from "../../components/DashboardLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { PlanSelector2 } from "../billing/plans";

const useStyles = makeStyles({
  dashboard: {
    width: "100vw",
    top: "84px",
    minHeight: `calc(100vh - 84px)`,
    height: "auto",
  },
  dashboardBody: {
    width: "calc(100vw - 240px)",
    height: "calc(100vh - 74px)",
    marginLeft: "240px",
    padding: "10px 40px",
    marginTop: "74px",
  },
  tabs: {
    display: "flex",
    fontWeight: "600",
    fontSize: "13px",
    gap: "10px",
    padding: "0px 15px",
  },
  title: {
    color: "#07070766 !important",
    fontWeight: 800,
    fontSize: "10px",
    marginTop: "10px",
    marginBottom: "5px",
    marginLeft: "15px",
  },
  tabContainer: {
    padding: "10px 0px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#F4F7FB",
    },
  },
  selectedTabs: {
    background: "#8DC63F !important",
  },
  pricingPlansContainer: {
    zIndex: 1,
    position: "relative",
  },
  "@media (max-width:767px)": {
    dashboardBody: {
      width: "100%",
    },
  },
});

const Profile = () => {
  utils.log.component("Profile");
  // #region Assertions
  const { employee } = useContext(AuthContext);
  const { navBarToggler } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("view") || "details"
  );
  const classes = useStyles();
  const handleTabClick = (tab) => {
    if (tab.id === "goBack") {
      navigate("/");
      return;
    }
    setActiveTab(tab.id);
    setSearchParams({ view: tab.id });
  };
  // #endregion

  // #region Functions
  const tabs = useMemo(
    () =>
      PROFILE_PAGE_TAB_LINKS.map((tab) => (
        <div
          key={tab?.id}
          className={`${classes.tabContainer} ${
            activeTab === tab?.id ? classes.selectedTabs : ""
          }`}
        >
          <div
            className={`${classes.tabs}`}
            onClick={() => handleTabClick(tab)}
          >
            <div className="tab-label">{tab?.label}</div>
          </div>
        </div>
      )),
    [activeTab]
  );

  const ActiveComponent = useMemo(
    () => PROFILE_PAGE_TAB_LINKS.find((tab) => tab.id === activeTab)?.component,
    [activeTab]
  );
  // #endregion

  if (!employee) {
    return null;
  }

  return (
    <DashboardLayout title="Manage your Profile">
      <div
        className={`${classes.dashboard}  ${
          navBarToggler ? classes.dashboardSecondary : ""
        }`}
      >
        <SideBar tabs={tabs} />

        <div className={classes.dashboardBody}>{ActiveComponent}</div>
      </div>
    </DashboardLayout>
  );
};
/* export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
}); */
export default Profile;

export const Details = () => {
  const { employee } = useContext(AuthContext);
  utils.log.component("Details", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Events
  const handleUpdate = async () => {
    utils.log.event("handleUpdate", employee);
    const result = await employeeService.update(cache.getDetails());

    if (!result.ok) {
      const error = await result.json();
      enqueueSnackbar(error.detail, { variant: "error", persist: true });
    } else {
      enqueueSnackbar("Success!", { variant: "success" });
    }
  };
  // #endregion
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  const { employeeService, cache } = useContext(AuthContext);
  const formElements = {
    // spacing: 2,
    data: { ...employee },
    commands: (
      <Grid item xs={12} sx={{ padding: "16px 42px 0px 42px" }}>
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button onClick={handleUpdate}>Update</Button>
        </Stack>
      </Grid>
    ),
    items: [
      {
        xs: 6,
        inputs: [{ label: "First Name", name: "firstName" }],
      },
      {
        xs: 6,
        inputs: [{ label: "Last Name", name: "lastName" }],
      },
      {
        xs: 12,
        inputs: [{ label: "Street address", name: "streetAddress" }],
      },
      {
        xs: 12,
        inputs: [{ label: "Street address2", name: "streetAddress2" }],
      },
      {
        xs: 6,
        inputs: [{ label: "City", name: "city" }],
      },
      {
        xs: 2,
        inputs: [{ label: "State", name: "state" }],
      },
      {
        xs: 4,
        inputs: [{ label: "Zip code", name: "zipCode" }],
      },
      {
        xs:4,
        inputs: [{ label: "UID", name: "uid" }],
      },
      {
        xs: 4,
        inputs: [{ label: "Email", name: "email", disabled: true }],
      },
      {
        xs: 4,
        inputs: [{ label: "Phone", name: "phone" }],
      },
    ],
  };
  // #endregion

  return (
    <Grid container sx={{ padding: "26px 42px 0px 42px" }}>
      <RenderForm formElements={formElements}></RenderForm>
    </Grid>
  );
};

export const Organizations = () => {
  const { employee } = useContext(AuthContext);
  utils.log.component("Organizations", employee);

  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const organisations = employee?.organizations || [];
  const columns = [
    { Header: "Organization", accessor: "legalName" },
    { Header: "Code", accessor: "code" },
  ];
  // #endregion
  // #region Events
  // #endregion
  return (
    <Grid container sx={{ padding: "16px 42px 0px 42px" }}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={organisations}
          selected
          onSelected={(row) =>
            utils.log.info(
              "Organisions table row click is not implemented",
              row
            )
          }
        ></Table>
      </Grid>
    </Grid>
  );
};

export const Messages = () => {
  const { employee } = useContext(AuthContext);
  utils.log.component("Messages", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const [list] = useState([]);
  const columns = [
    { Header: "Sender", accessor: "sender" },
    { Header: "Received", accessor: "dateCreated" },
    { Header: "Subject", accessor: "subject" },
  ];
  // #endregion
  // #region Events
  // #endregion

  return (
    <Grid container sx={{ padding: "16px 42px 0px 42px" }}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => alert("Not implemented")}
        ></Table>
      </Grid>
    </Grid>
  );
};
export const Plan = () => {
  const classes = useStyles();
  const { employee } = useContext(AuthContext);
  const { plan: activePlan } = employee;
  utils.log.component("Plan", activePlan);
  // #region Assertions
  utils.assert(activePlan != null, "plan is required");

  const { planService } = useContext(AuthContext);
  const [plans, setPlans] = useState([]);

  const fetchPlans = async () => {
    try {
      const response = await planService.getSubscriptionPlans();
      if (response) {
        const formattedPlansInfo = response.map((items, index) => {
          return {
            ...items,
            heading: items?.code || "Starter",
            description:
              items?.description ||
              "Get your starter plan for getting small scale services.",
            price: `${items?.price}`,
            btnText: "Change Plan",
            services: [
              {
                service: `Up to ${
                  items?.surveyMax.toLocaleString() || 0
                } surveys`,
              },
              {
                service: `Up to ${
                  items?.coordinatorMax.toLocaleString() || 0
                } coordinators`,
              },
              {
                service: `Up to ${
                  items?.questionMax.toLocaleString() || 0
                } questions/survey`,
              },
              /*               {
                service: `${
                  items?.dataRetentionDays || 0
                } > Data Retentions Days`,
              }, */
              {
                service: `${
                  items?.surveyRecipientMax.toLocaleString() || 0
                } recipients/survey`,
              },
              {
                service: `${
                  items?.includedSurveyResponses.toLocaleString() || 0
                } survey responses included`,
              },
              /*               {
                service: `${items?.reportsIncluded || 0} Reports Included`,
              }, */
              /*               {
                service: `${items?.dataRetentionDays || 0} day data retention`,
              }, */
              /*               {
                service: items?.enableLiveResults
                  ? "Live Results"
                  : "Live Results (Not Included)",
              },
              {
                service: items?.includeLayouts
                  ? "Include Layouts"
                  : "Layouts (Not Included)",
              }, */
              /*               {
                service: items?.reportsIncluded
                  ? "Reports Included"
                  : "Reports (Not Included)",
              }, */
            ],
          };
        });
        setPlans(formattedPlansInfo);
      }
      utils.log.stateChange(`PlanSelector.Init`, plans);
    } catch (error) {
      utils.log.error("Error fetching and setting plans:", error);
      // Handle errors as needed
    }
  };

  useEffect(() => {
    utils.log.useEffect(`PlanSelector`, { plans });
    fetchPlans();
  }, []);
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  // #endregion
  // #region Events
  // #endregion

  return (
    <div className={classes.pricingPlansContainer}>
      {plans.length > 0 && (
        // <PricingCard
        //   showAd={false}
        //   pricingArr={plans || []}
        //   // handleClick={() => alert("Not implemented")}
        //   activePlan={activePlan}
        // />
        <PlanSelector2
        plans={plans}
        employee={employee}
        />
      )}
    </div>
  );
};
export const Billing = () => {
  const { employee, employeeService } = useContext(AuthContext);
  utils.log.component("Billing", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const [list, setList] = useState([]);

  const columns = [
    { Header: "Order Id", accessor: "orderId" },
    {
      Header: "Order Date",
      accessor: "orderDate",
      Cell: ({ row }) =>
        row.original.orderDate
          ? dayjs(row.original.orderDate).format("YYYY-MM-DD HH:mm")
          : "N/A",
    },
    { Header: "Description", accessor: "description" },
    { Header: "Code", accessor: "code" },
    { Header: "Amount", accessor: "amount", align: "right" },
    { Header: "Ordered By", accessor: "orderedBy" },
  ];
  const fetchTransactions = async (email) => {
    try {
      const response = await employeeService.getEmployeeTransactions(email);
      if (response) {
        setList(response);
      }
      utils.log.stateChange(`Billing.Init`, list);
    } catch (error) {
      utils.log.error("Error fetching and setting transactions:", error);
    }
  };

  useEffect(() => {
    if (employee) {
      fetchTransactions(employee.email);
    }
  }, [employee]);
  // #endregion
  // #region Events
  // #endregion

  return (
    <Grid container sx={{ padding: "16px 42px 0px 42px" }}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => alert("Not implemented")}
        ></Table>
      </Grid>
    </Grid>
  );
};
