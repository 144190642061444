import { Grid } from "@mui/material";
import Button from "../../components/Button";
import Table from "../../components/Table";
import Form from "../../components/Form";
import FormSelect from "../../components/Select";
import { useMemo } from "react";
import { useForm } from "../../hooks/useForm";
import Input from "../../components/Input/inputs";
import DashboardLayout from "../../components/DashboardLayout";

export default function Recipients() {
  const list = [
    {
      name: "North Campus",
      mailingAddress: "1 North Way, Anytown, CA, 90000",
      coordinator: "W.W. North",
    },
    {
      name: "South Campus",
      mailingAddress: "2 South Way, Anytown, CA, 90000",
      coordinator: "W.W. South",
    },
    {
      name: "East Campus",
      mailingAddress: "3 East Way, Anytown, CA, 90000",
      coordinator: "W.W. East",
    },
    {
      name: "West Campus",
      mailingAddress: "4 West Way, Anytown, CA, 90000",
      coordinator: "W.W. West",
    },
  ];
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Mailing Address", accessor: "mailingAddress" },
    { Header: "Coordinator", accessor: "coordinator" },
  ];

  const memoFields = useMemo(
    () => [
      /*       { Key: "id" }, */
      { Key: "name" },
      { Key: "streetAddress1" },
      { Key: "streetAddress2" },
      { Key: "city" },
      { Key: "state" },
      { Key: "zipCode" },
      { Key: "coordinator" },
    ],
    []
  );

  const { getFieldProps, getFormProps, resetFields } = useForm({
    fields: memoFields,
    handleSubmit: async (e, fields) => {
      e.preventDefault();
      //let response = await AssociateService.put("", fields);
      //fields.id = response.id;

      resetFields();
    },
  });

  return (
    <DashboardLayout title="Recipients">
      <Form {...getFormProps()}>
        <Grid container >
          <Grid item xs={12}>
            <h1>Recipients</h1>
          </Grid>
          <Grid item xs={10}>
            <Input label="Search" {...getFieldProps("name")} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => alert("Not implemented.")}>Search</Button>
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              label="Department"
              data={[{ label: "Test", value: "Test" }]}
              {...getFieldProps("name")}
              fullWidth
            ></FormSelect>
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              label="Survey"
              data={[{ label: "Test", value: "Test" }]}
              {...getFieldProps("name")}
              fullWidth
            ></FormSelect>
          </Grid>
          <Grid item xs={12} sx={{ padding:"16px 42px 0px 42px"}}>
            <Table
              columns={columns}
              data={list}
              selected
              actions={{
                delete: (e, row) => {
                  debugger;
                },
              }}
            ></Table>
          </Grid>
        </Grid>
      </Form>
    </DashboardLayout>
  );
}
