import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  cardWrapper:{
    cursor:"pointer"
  },
  cardTitle: {
    [theme.breakpoints.down("md")]:{
      fontSize:"16px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  cardImage: {
    borderRadius: "10px 10px 0px 0px",
  },
  cardInfo: {
    padding: "25px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: "0px 0px 10px 10px",
    [theme.breakpoints.down("md")]:{
      padding: "15px",
      fontSize:"16px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      fontSize: "12px",
    },
  },
  cardLearnMore:{
    color: "#007BFF", 
  }
}));
export const BlogCard = ({ item }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/blog/${item?.slug}`)
  }
  return (
    <div className={classes.cardWrapper} onClick={() => handleCardClick()}>
      <img width={"100%"} src={item.image} className={classes.cardImage} alt="blog-card"/>
      <div className={classes.cardInfo}>
        <h5 className={classes.cardTitle}>{item?.title}</h5>
        <span className={classes.cardLearnMore}>Learn More</span>
      </div>
    </div>
  );
};
