// TODO: 102323 Implement Merge Fields INSERT button at cursor position in Subject or Body template
import { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Button from "../../../components/Button";
import FormSelect from "../../../components/Select";
import Grid from "../../../components/Grid";
import Input from "../../../components/Input/inputs";
import * as utils from "../../../services/utilities";
import { API_KEY_TINY_MCE } from "../../../utils/env";

export default function Emails({ survey }) {
  utils.log.component("Emails", survey);
  // #region Assertions
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const editorRef = useRef(null);
  const messageTypes = [
    "Invitation",
    "Reminder",
    "Followup",
    "Exemption",
    "Completed",
  ];
  //const messageTypes = survey.messageTemplates.map((t) => t.code);
  const mergeFields = [
    { label: "First name", value: "recipient.firstName" },
    { label: "Last name", value: "recipient.lastName" },
    { label: "UID", value: "recipient.uid" },
    { label: "Survey.Start", value: "survey.effectiveDate" },
    { label: "Survey.End", value: "survey.expireDate" },
  ];
  const messageTypesList = utils.toSelectItem(messageTypes);
  const [messageTemplate, setMessageTemplate] = useState(
    survey.messageTemplates[0]
  );

  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("Initialize", messageTemplate);
  }, []);
  const handleMessageType = (e) => {
    e.preventDefault();
    const messageType = e.target.value;

    utils.log.info(`MessageTemplateType(selected): ${messageType}`);
    const template = survey.messageTemplates.find(
      (t) => t.code.toUpperCase() === messageType.toUpperCase()
    );
    //utils.log.debug(`MessageTemplate: ${messageType}`, template);
    /*     cache.set("subject", template.subject);
    cache.set("body", template.body); */
    setMessageTemplate(template);
    utils.log.stateChange(`setMessageTemplate(${messageType})`, template);
  };
  const handleChange = (e) => {
    const target = e.target.name;
    const value = e.target.value;
    utils.log.info(
      `MessageTemplate.${target}(changed): ${value}`,
      messageTemplate
    );

    // Create a new object that's a copy of the current state
    const updatedMessageTemplate = { ...messageTemplate, [target]: value };

    // Update the state with the new object
    setMessageTemplate(updatedMessageTemplate);
    utils.log.stateChange(
      `setMessageTemplate(messageTemplate)`,
      updatedMessageTemplate
    );

    const template = survey.messageTemplates.find(
      (t) => t.code.toUpperCase() === messageTemplate.code.toUpperCase()
    );
    template.subject = updatedMessageTemplate.subject;
    template.body = updatedMessageTemplate.body;
  };
  // #endregion

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormSelect
            debug
            caseInsensitive
            label="Message Type"
            defaultValue={messageTemplate.code}
            fullWidth
            data={messageTypesList}
            onChange={handleMessageType}
          ></FormSelect>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormSelect
            label="Merge Fields"
            data={mergeFields}
            defaultValue={mergeFields[0].value}
            fullWidth
          ></FormSelect>
        </Grid>
        <Grid item xs={4}>
          <Button disabled style={{ padding: "16.5px 40px" }}>
            Insert
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Input
            name="subject"
            label="Subject template"
            fullWidth
            onChange={handleChange}
            value={messageTemplate.subject}
          ></Input>
        </Grid>
        <Grid item xs={12}>
          <Editor
            // TODO: 051323 Complete implementation of image insert
            // BUG: 111723 https://github.com/GoPassGoNetworks/AVR/issues/31
            apiKey={API_KEY_TINY_MCE}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            value={messageTemplate.body}
            onEditorChange={(content, editor) => {
              const event = {
                target: {
                  name: "body",
                  value: content,
                },
              };

              handleChange(event);
            }}
            init={{
              height: "250",
              menubar: false,
              branding: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount textcolor",
              ],
              toolbar:
                "undo redo | formatselect | image | " +
                "bold italic | forecolor backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </Grid>

        {/*         <Grid item container xs={12} justifyContent="flex-end">
          <Button>Update</Button>
        </Grid> */}
      </Grid>
    </>
  );
}
