import { makeStyles } from "@mui/styles";
import DashboardLayout from "../../components/DashboardLayout";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { Link } from "react-router-dom";
import { CONTACTUS_INFO } from "../../utils/contentData";
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#fff",
    padding: "10px 20px",
    border: "1px solid #ccc",
    borderRadius: "12px",
  },
  span: {
    fontSize: "15px",
    color: "#444",
  },
  email: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

export const HelpSupport = () => {
  const styles = useStyles();

  return (
    <DashboardLayout
      title="Get Help"
      description="Stuck somewhere? Reach out to us for help!"
    >
      <div className={styles.main}>
        <p>Please write to us on the below mail by explaining the issue:</p>
        <Link
          to={`mailto:${CONTACTUS_INFO?.email}`}
          target="_blank"
        >
          <p>
            <AttachEmailIcon />
            <span>{CONTACTUS_INFO?.email}</span>
          </p>
        </Link>

        <p className={styles.span}>We will reach back to you in 24hrs!</p>
      </div>
    </DashboardLayout>
  );
};
