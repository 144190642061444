import { makeStyles } from "@mui/styles";
import failedIcon from "../../assets/cancel.svg";
import successIcon from "../../assets/check-circle.svg";
import Button from "../../components/Button";
import * as utils from "../../services/utilities";
import { Link } from "react-router-dom";

export default function Payment({ result }) {
  debugger;
  return <h1>Payment {result}</h1>;
}

export const PaymentSuccess = () => {
  const sessionId = utils.getQuerystring("session_id");

  const classes = useStyles();

  if (!sessionId) return null;

  return (
    <div className={classes.root}>
      <div>
        <img src={successIcon} alt="success-payment" className={classes.icon} />
        <p className={classes.title}>Payment successfull</p>
        <p className={classes.description}>You need to login to continue.</p>
      </div>
      <div>
        <Link to="/">
          <Button variant="contained" size="small">
            Go to Login
          </Button>
        </Link>
      </div>
    </div>
  );
};

export const PaymentFailed = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <img src={failedIcon} alt="failed-payment" className={classes.icon} />
        <p className={classes.title}>Payment failed</p>
        <p className={classes.description}>Contact your organisation lead</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 100,
  },
  icon: {
    height: 36,
    marginBottom: 12,
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
  },
  description: {
    fontSize: "18px",
    color: "#7e7e7e",
  },
}));
