import { makeStyles } from "@mui/styles";
import { Typography } from "antd/lib";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]:{
      flexDirection:"column",
      alignItems:"start",
      gap:"15px"
    }
  },
  headerInfo: {
    maxWidth: "70%",
    [theme.breakpoints.down("md")]:{
      maxWidth:"100%"
    }
  },
  title: {
    color: "#070707",
    fontWeight: "700",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]:{
      fontSize:"20px"
    }
  },
  description: {
    fontWeight: "400",
    fontSize: "15px",
    "& p": {
      margin: 0,
      fontWeight: 700,
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]:{
      fontSize:"14px"
    }
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const DashboardLayout = ({
  title,
  description,
  actionButtons = null,
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerInfo}>
          {title ? (
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography className={classes.description}>
              {description}
            </Typography>
          ) : null}
        </div>

        <div>
          {actionButtons && (
            <div className={classes.button}>{actionButtons}</div>
          )}
        </div>
      </div>

      {children}
    </>
  );
};

export default DashboardLayout;
