import Proptypes from "prop-types";
import React, { useReducer } from "react";

const updateState = (state, action) => {
  let newValue;

  if (action.payload.type === "checkbox") {
    newValue = action.payload.checked;
  } else {
    newValue = action.payload.value;
  }

  if (state.hasOwnProperty(action.key)) {
    return {
      ...state,
      [action.key]: { ...state[action.key], value: newValue }, 
    };
  }

  throw new Error("Wrong key was given for state update in UseForm");
};

function fieldReducer(state, action) {
  switch (action.type) {
    case "update":
      return updateState(state, action);
    case "initial":
      return action.initial;
    default:
      return state;
  }
}

function MapFieldState(fields) {
  let mappedFields = {};

  if (fields === null || fields === undefined) {
    return mappedFields;
  }

  fields.forEach((field) => {
    const { Key, defaultValue, ...rest } = field;
    if (mappedFields.hasOwnProperty(Key)) {
      throw new Error(
        `Key [${field.Key}] for Form repeats. Invalid. Please make only unique Keys`
      );
    }

    mappedFields[field.Key] = {
      value: defaultValue ?? "",
      defaultValue: defaultValue ?? "",
      ...rest,
    };
  });
  return mappedFields;
}
export function useForm({ fields, handleSubmit }) {
  const [formFields, dispatch] = useReducer(
    fieldReducer,
    MapFieldState(fields)
  );

  React.useEffect(() => {
    dispatch({ type: "initial", initial: MapFieldState(fields) });
  }, [fields]);

  const getFieldProps = (key) => {
    if (!formFields[key]) {
      throw new Error(
        `Field Property '${key}' does not exist. Please enter correct Key.`
      );
    }

    const result = {
      value: formFields[key].value,
      onChange: (e) => {
        dispatch({ type: "update", key: key, payload: e.target });
      },
    };

    if (formFields[key].type) {
      result.type = formFields[key].type;
    }

    return result;
  };

  const getFormProps = () => {
    const fields = {};
    Object.keys(formFields).map((key) => (fields[key] = formFields[key].value));

    return { onSubmit: (event) => handleSubmit(event, fields) };
  };

  return {
    formFields,
    resetFields() {
      dispatch({ type: "initial", initial: MapFieldState(fields) });
    },
    getFieldProps,
    getFormProps() {
      return { ...getFormProps() };
    },
  };
}

useForm.propTypes = {
  fields: Proptypes.arrayOf(
    Proptypes.shape({
      Key: Proptypes.string.isRequired,
      type: Proptypes.string,
    })
  ).isRequired,
  handleSubmit: Proptypes.func.isRequired,
};
