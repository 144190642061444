import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import Grid from "../Grid";
import Input from "../Input/inputs";

export default function RenderForm({ formElements }) {
  utils.log.component("RenderForm", formElements);
  utils.assert(formElements != null, "formElements are required");
  const { cache } = useContext(AuthContext);
  cache.clear();
  cache.setDetails(formElements.data);
  useEffect(() => {
    utils.log.useEffect(`RenderForm`, formElements.items);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {formElements.items.map((item, index) => {
          return (
            <Grid item xs={item.xs ?? 12} key={"Grid-" + index}>
              {item.inputs.map((input, index) => {
                if (formElements.data[input.name] === undefined)
                  utils.log.warn(
                    `RenderForm: ${input.name} is undefined`,
                    item
                  );
                return (
                  <Input
                    {...input}
                    key={input.name + index}
                    label={input.label}
                    name={input.name}
                    fullWidth={input.fullWidth ?? true}
                    onChange={(e) => cache.set(e)}
                    defaultValue={cache.get(input.name)}
                    disabled={input.disabled || false}
                  />
                );
              })}
            </Grid>
          );
        })}
        {formElements.commands}
      </Grid>
    </>
  );
}
