import React, { useContext } from "react";

import {  RadioGroup } from "@mui/material";
import * as config from "../../services/config";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { Choice, QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";
import { ChoiceSelector } from "./dependencySelector";
export function New() {
  return {
    type: "SingleChoice",
    code: null,
    title: "New Single Choice Question",
    description: "",
    instructions: "",
    dependency: null,
    choices: [],
    answer: null,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  utils.log.component("Reset(SingleChoice):", question);
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  utils.log.component("Edit(SingleChoice):", question);
  return null;
}
export const DependencySelector = ({ context, selectedQuestion }) => {
  // !NOTE: useState() is prohibited in this component.
  return (
    <ChoiceSelector
      context={context}
      selectedQuestion={selectedQuestion}
    ></ChoiceSelector>
  );
};

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);

  return dependency;
}

export function Render(props) {
  utils.log.component("SingleChoice.Render()", props);
  // #region Initialize

  const { index, question, rowStyle ,preview} = props;
  const validationMessage = ""; //const [validationMessage, setValidationMessage] = useState(""); // REQUIRED
  // #endregion
  const isQuestionCompleted  = isCompleted(props.question);
  return (
    <div
      key={index}
      style={{
        ...rowStyle,
        border:isQuestionCompleted ? "1px solid #3BDB41" : "1px solid #E9E9E9",
      }}
    >
    { !preview && <div style={{ fontSize: "18px", fontWeight: "700" }}>Q{index + 1}</div>}
      <div style={{width:"100%",padding:preview ? "30px" : 0,}}>
        <QuestionHeader question={question}></QuestionHeader>
        <RenderChoices {...props}></RenderChoices>
        {validationMessage}
      </div>
    </div>
  );
}

export const RenderChoices = (props) => {
  utils.log.component("SingleChoice.RenderChoices()", props);
  // TODO: question.isDropdown is not implemented
  // TODO: Allow recipient to select custom comment (question.isCommentAllowed)
  // #region Initialize

  const { surveyService } = useContext(AuthContext);
  const preview = props.preview ?? false;
  const question = props.question;
  const choices = question.choices; //const [choices, setChoices] = useState(question.choices);
  const selectedChoice = choices.find((c) => c.isSelected) ?? null; // !KLUDGE 060623 - Until we can resolve the functions being stripped by spread operator.
  const selectedValue = selectedChoice?.valueFormatted?.() ?? "";
  const choicesFiltered = preview
    ? choices // Show all choices in preview mode
    : choices.filter((c) => c.isVisible);
  // utils.log.info(
  //   `RenderChoices: question: ${props.question.code} isVisible: ${
  //     props.question.isVisible
  //   } selectedChoice: ${selectedChoice?.valueFormatted(".") ?? "- none -"} `
  // );
  // #endregion
  // #region Events

  const handleSelected = (choice) => {
    const choiceFormatted = choice.valueFormatted?.(".");

    utils.log.event(`handleSelected(${choiceFormatted})`, choice);

    // *** Update question state ***
    if(preview){
      props.setQuestions((qun)=>{
        const updatedChoices = props.question.choices.map((c, index) => {
          if (c.code === choice.code) {
            return { ...c, isSelected: true };
          } else {
          return { ...c, isSelected: false };
          }
        })
        return{
          ...qun,
          choices: updatedChoices
        }
      })
    }else{
      props.setQuestions((prevQuestions) => {
        const questionCode = choice.question().code;
        // *** Set selected choice ***
        let updatedQuestions = prevQuestions.map((prevQuestion) => {
          if (prevQuestion.code === questionCode) {
            // Create a new copy of the choices array with updated isSelected value
            const updatedChoices = prevQuestion.choices.map((c, index) => {
              utils.log.info(`Checking choice(${c.code} index: ${index})`, c);
              if (c.code === choice.code) {
                utils.log.info(
                  `handleSelected(${choiceFormatted}): Choice(${questionCode}.${c.code}) found and set to isSelected = true`,
                  config.cssStyle.success
                );
                return { ...c, isSelected: true };
              } else return { ...c, isSelected: false };
            });
            const updatedChoicesSelected = updatedChoices.filter(
              (c) => c.isSelected
            );
            utils.assert(
              updatedChoicesSelected != null,
              "handleSelected: No choice was selected."
            );
            utils.assert(
              updatedChoicesSelected.length === 1,
              `handleSelected: Only a single choice(${updatedChoicesSelected.length}) is allowed.`
            );
  
            // Update the choices array of the parent question
            return { ...prevQuestion, choices: updatedChoices };
          }
          return prevQuestion;
        });
  
        // Resolve visibility for all questions
        // if (!preview) dependencyUtil.resolveVisibilities(updatedQuestions);
  
        utils.log.stateChange(
          `*** STATE CHANGE *** handleSelected(${choiceFormatted})`,
          updatedQuestions
        );
  
        return updatedQuestions;
      });
    }
  };
  // #endregion

  return (
    <>
      <RadioGroup defaultValue="" value={selectedValue} row>
        {choicesFiltered.map((choice) => {
          return (
            <Choice
              key={choice.code}
              choice={choice}
              handleSelected={handleSelected}
              renderAs={question.isDropdown ? "dropdown" : "radio"}
            ></Choice>
          );
        })}
        {question.isCommentAllowed && (
          <Choice
            key={"choice.code"}
            choice={surveyService.newChoice("code", "value", "description")}
            handleSelected={handleSelected}
            renderAs="comment"
          ></Choice>
        )}
      </RadioGroup>
    </>
  );
};

// #region Dependency
export function isAnswered(dependency) {
  // PURPOSE: Determine if the dependency is satisfied.
  if (dependency == null) {
    utils.log.info(`SingleChoice.isAnswered(false): dependency is null`);
    return false;
  }

  switch (dependency.type) {
    case "question": // TRUE: If any choice is selected.
      // TODO(LOW): When we figure out how to restore survey functions, convert checkedCount to that.
      if (
        dependency?.question?.choices?.find(
          (c) => c.code === dependency?.choice?.code
        )?.isSelected
      ) {
        utils.log.info(
          "SingleChoice.isAnswered(true): Choice was selected.",
          dependency.question.choices
        );
        return true;
      } else
        utils.log.warn(
          `SingleChoice.isAnswered(false): Dependency on question(${dependency.question.code}) which requires at least one choice selected.`,
          dependency
        );
      break;
    case "choice": // TRUE: If this choice is selected.
      if (dependency.choice.isSelected) {
        utils.log.info(
          `SingleChoice.isAnswered(true): Choice(${dependency.choice.valueFormatted?.(
            "."
          )}) was selected.`
        );
        return true;
      } else
        utils.log.warn(
          `SingleChoice.isAnswered(false): Choice(${dependency.choice.valueFormatted?.(
            "."
          )}) has to be selected.`,
          dependency
        );
      break;
    default:
      throw new Error(
        `SingleChoice.isAnswered: Invalid dependency type: ${dependency.type}`
      );
  }
  return false;
}

export const isCompleted = (question) => {
  utils.log.info(":: Running SingleChoice.isCompleted()", question);
  if (!question) {
    utils.log.warn("SingleChoice.isCompleted(false): question is null");
    return false;
  }
  const isChoiceSelected = question.choices.find((c) => c.isSelected);

  return Boolean(isChoiceSelected);
};

export const getValue = (question) => {
  return {
    value: question.choices.find((c) => c.isSelected)?.code || "",
  };
};
// #endregion
