// TODO: Question validation.
// PURPOSE: Contains functions related to questions.

import DOMPurify from "dompurify";
import * as config from "./config";
import * as utils from "./utilities";
export default class QuestionService {
  constructor(headers) {
    this.headers = headers;
  }
}
export const renderHtml = (html) => {
  // PURPOSE: Sanitizes html and returns it as a string.
  const result = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: config.defaults.ALLOWED_TAGS,
    ALLOWED_ATTR: config.defaults.ALLOWED_ATTR,
  });
  return result;
};

export const updateQuestionAnswer = (
  question,
  answer,
  preview,
  setQuestions,
  questions
) => {
    if(preview){
    setQuestions((qun)=>{
      return{
        ...qun,
        answer:answer
      }
    })
  }else{
  setQuestions(prev=>{
    const previousQuestions = [...prev];

    const stagedQuestions = previousQuestions.map(q=>{
      
      if (q.code === question.code) {
        return {
          ...q,
          answer:answer
        }
      } else {
        return q
      }
    })
    return stagedQuestions
  })
}
};

export const initValueRangeObject = (minValue, maxValue) => {
  utils.assert(typeof minValue !== "object", "minValue must be a value type.");
  utils.assert(typeof maxValue !== "object", "maxValue must be a value type.");
  utils.log.info("initValueObject()");
  return { min: minValue, max: maxValue };
};

export const initAnswerObject = (start, end) => {
  return { start, end };
};
