import * as utils from "./utilities";
export default class Cache {
  constructor() {
    this.cache = {};
  }
  get(key) {
    utils.log.disabled(`Cache.get(${key}: ${this.cache[key]})`, this.cache);
    return this.cache[key];
  }
  set(key, value) {
    if (utils.isSyntheticEvent(key) || utils.isEvent(key)) {
      this.cache[key.target.name] = key.target.value;
      utils.log.debug(
        `Cache.set(key: ${key.target.name}, value: ${key.target.value}) - Synthetic Event`,
        this.cache
      );
    } else {
      this.cache[key] = value;
      utils.log.debug(
        `Cache.set(key: ${key}, value: ${value}) - Normal Event`,
        this.cache
      );
    }
  }
  setDetails(detail, context) {
    utils.log.disabled("Cache.setDetails", detail, context);
    this.clear();
    this.cache = detail;
  }
  getDetails() {
    utils.log.disabled("Cache.getDetails", this.cache);
    return this.cache;
  }
  clear() {
    utils.log.disabled("Cache.clear");
    this.cache = {};
  }
}
