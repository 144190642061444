import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as utils from "./utilities";
import EmployeeService from "./employee";
import WorksiteService from "./worksite";
import DepartmentService from "./department";
import SurveyService from "./survey";
import RecipientService from "./recipient";
import PaymentService from "./payment";
import OrderService from "./order";
import PlanService from "./plan";
import NoticeService from "./notice";
import Cache from "./cache";
import Error from "./error";
import ExemptionService from "./exemption";
import { API_URL, ENVIRONMENT } from "../utils/env";

export const AuthContext = React.createContext({});

export function ApiProvider({ children }) {
  const [token, setToken] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [employeeService, setEmployeeService] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  const setUpEmployee = async () => {
    setIsEmployeeLoading(true);
    utils.log.debug("context.js setUpEmployee()", user, headers);
    let employeeService = new EmployeeService(user.email, headers);
    const employeeResponse = await employeeService.get(user.email, headers);
    employeeService.currentEmployee = employeeResponse;
    setEmployeeService(employeeService);
    setEmployee(employeeResponse);
    // Redirect to home page if user is on provisioning page
    // But has organization already
    if (
      window.location.pathname.includes("provisioning") &&
      employeeResponse?.organization
    ) {
      window.location.href = "/";
    }
    setIsEmployeeLoading(false);
  };

  const getAuthToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setToken(token);
      setHeaders(getHeaders(token));
    } catch (error) {
      utils.log.error(`context.js callToken(error: ${error})`);
    }
  };

  useEffect(() => {
    getAuthToken();
  }, []);

  useEffect(() => {
    if (token && user && headers) {
      setUpEmployee();
    } else {
      setIsEmployeeLoading(false);
    }
  }, [user]);

  const contextValue = useMemo(
    () => ({
      employee,
      token,
      setToken,
      headers,
      apiOrigin: getApiOrigin(),
      employeeService,
      worksiteService: headers ? new WorksiteService(headers) : null,
      departmentService: headers ? new DepartmentService(headers) : null,
      surveyService: headers ? new SurveyService(headers) : null,
      recipientService: headers ? new RecipientService(headers) : null,
      exemptionService: headers ? new ExemptionService(headers) : null,
      orderService: headers ? new OrderService(headers) : null,
      paymentService: headers ? new PaymentService(headers) : null,
      planService: headers ? new PlanService(headers) : null,
      noticeService: headers ? new NoticeService(headers) : null,
      cache: new Cache(),
      error: new Error(),
      isDevelopment: isDevelopment(),
      setUpEmployee,
      isEmployeeLoading,
      setHeaders, //for modifying Tokens,
      setEmployee,
      setEmployeeService,
      setIsEmployeeLoading,
    }),
    [employee, token, headers, employeeService, isDevelopment, setUpEmployee]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function getHeaders(token) {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
}
export function isDevelopment() {
  return ENVIRONMENT === "development";
}
export function getApiOrigin() {
  return API_URL;
}
