import CxBx from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

function Checkbox(props) {
  const { control, ...rest } = props;

  return <FormControlLabel control={<CxBx />} {...rest} />;
}

export default function Input(props) {
  return <TextField {...props} name={props.name ?? props.label} />;
}

export { Checkbox };

Input.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fieldName: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.node,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["medium", "small"]),
    PropTypes.string,
  ]),
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};
