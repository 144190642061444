import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../services/context";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import Tabs from "../../../components/Tabs";
import Table from "../../../components/Table";
import * as utils from "../../../services/utilities";
/* import Summary from "./summary"; */
import Find from "./find";
import { SurveySelector } from "../../surveys";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
import Detail from "../detail";
import dayjs from "dayjs";
import * as config from "../../../services/config";
import DashboardLayout from "../../../components/DashboardLayout";
import { HeaderActions } from "../../../components/HeaderActions";
const dateTimeDisplayFormat = `${config.defaults.DATE_DISPLAY_FORMAT} ${config.defaults.HOUR_DISPLAY_FORMAT}`;

export const Overview = () => {
  // #region Assertions
  // #endregion
  // #region Functions
  const fetchSurveyByCode = async (surveyCode) => {
    try {
      const survey = await surveyService.getByCode(surveyCode);
      setSurvey(survey);
      const recipientCount = await surveyService.getRecipientCount(surveyCode);
      const isAllowed_SurveyRecipientAdd =
        await employeeService.isAllowed_SurveyRecipientAdd(recipientCount);
      setIsAllowed_SurveyRecipientAdd(isAllowed_SurveyRecipientAdd);
      utils.log.stateChange(
        `setSurvey(${surveyCode}),setIsAllowed_SurveyRecipientAdd(${isAllowed_SurveyRecipientAdd}), recipientCount: ${recipientCount}`,
        survey
      );
    } catch (error) {
      utils.log.error(`Error fetching survey (${surveyCode})`, error);
    }
  };
  // #endregion
  // #region Initialize
  const { surveyService, employeeService } = useContext(AuthContext);
  const [surveyCode, setSurveyCode] = useState("");
  const [survey, setSurvey] = useState({});
  // const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [isAllowed_SurveyRecipientAdd, setIsAllowed_SurveyRecipientAdd] =
    useState(false);

  // #endregion
  // #region Events
  useEffect(() => {
    if (surveyCode) {
      fetchSurveyByCode(surveyCode);
    }
  }, [surveyCode]);
  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  // #endregion
  const headerAction = (
    <HeaderActions
      text={"Add"}
      onAdd={handleAddOpen}
      disabled={!isAllowed_SurveyRecipientAdd}
    />
  );
  utils.log.debug(`disabled: ${!isAllowed_SurveyRecipientAdd}`);
  return (
    <>
      <div style={{ margin: "20px 0px" }}>
        <SurveySelector
          filter="enabled"
          selected={surveyCode}
          setSelected={setSurveyCode}
          label="Select a survey"
        ></SurveySelector>
      </div>
      <DashboardLayout
        title={"Recipients"}
        description={"Manage any recipient issues here."}
        button={headerAction}
      >
        {surveyCode && (
          <div>
            <Tabs>
              <Tab label="Issues" component={<Issues survey={survey} />} />
              {/*             <Tab
              label="Summary"
              component={<Summary survey={survey}></Summary>}
            /> */}
              <Tab label="Find" component={<Find survey={survey}></Find>} />
            </Tabs>
          </div>
        )}

        {surveyCode && (
          <Dialog open={addOpen} onClose={handleAddClose} maxWidth={"md"} PaperProps={{style:{height:"600px"}}}>
            <DialogTitle sx={{ textAlign: "center" }}>
              Add Recipients
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "20px" }}>
              <Detail
                recipient={{}}
                mode="add"
                handlAddClose={handleAddClose}
                surveyCode={surveyCode}
              />
            </DialogContent>
          </Dialog>
        )}
      </DashboardLayout>
    </>
  );
};

export const Issues = ({ survey }) => {
  // #region Assertions
  utils.assert(survey, "survey is required");
  // #endregion
  // #region Functions
  const fetchIssues = async () => {
    try {
      const issues = await recipientService.getIssues(survey.code);
      setList(issues);
      utils.log.stateChange("setList(issues)", issues);
    } catch (error) {
      utils.log.error("Error fetching issues", error);
    }
  };
  // #endregion
  // #region Initialize
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [recipient, setRecipient] = useState([]);

  const { recipientService } = useContext(AuthContext);

  const contactHistoryColumns = [
    {
      Header: "Date",
      accessor: "contactDate",
      Cell: ({ cell: value }) => {
        const formattedDate = dayjs(value.row.original.contactDate).format(
          dateTimeDisplayFormat
        );
        return value.value !== undefined ? formattedDate : "???";
      },
    },
    { Header: "Contact Method", accessor: "contactMethod" },
  ];

  const [list, setList] = useState([]);
  const columns = [
    { Header: "Recipient", accessor: "fullName" },
    { Header: "UID", accessor: "uid" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Issue", accessor: "comment" },
  ];

  // #endregion
  // #region Events
  useEffect(() => {
    if (survey.code) {
      fetchIssues();
    }
  }, [survey]);

  const handleEdit = (recipient) => {
    setRecipient(recipient);
    setIsAddModalOpen(true);
  };

  // #endregion

  return (
    <div>
      <Table
        columns={columns}
        data={list}
        selected
        onSelected={(row) => handleEdit(row.original)}
      />

      <Dialog
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{style:{height:"600px"}}}
      >
        <DialogTitle>Recipient</DialogTitle>
        <DialogContent>
          <Grid container spacing="2">
            <Grid item xs={12} md={6}>
              <Detail
                surveyCode={survey.code}
                recipient={recipient}
                mode="update"
              ></Detail>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ padding: "16px" }}>
                <Table
                  columns={contactHistoryColumns}
                  data={recipient.contactHistory}
                  selected
                  caption="Contact History"
                  onSelected={(row) => handleEdit(row.original)}
                ></Table>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button>Cancel</Button>
          <Button>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
