import React from "react";
import { makeStyles } from "@mui/styles";
import { FOOTER_LINKS, FOOTER_SOCIAL_LINKS } from "../utils/contentData";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

const useStyles = makeStyles(() => ({
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    gap: "20px",
    lineHeight: "19.2px",
    "& a": {
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
  footer: {
    display: "flex",
    backgroundColor: "rgb(25, 30, 42)",
    minHeight: "400px",
    padding: "50px 100px",
    boxShadow: "none",
    textAlign: "start",
    justifyContent: "space-between",
    gap: "100px",
    width: "100%",
    bottom: 0,
    "@media(max-width:540px)": {
      padding: "50px 20px",
    },
  },
  container: {
    display: "flex",
    width: "100%",
    gap: "10px",
    justifyContent: "space-around",
  },
  socialContainer: {
    maxWidth: "280px",
    "& p": {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      fontFamily: "Inter",
    },
    "& div": {
      gap: "22px",
      display: "flex",
      flexDirection: "column",
      "& div": {
        display: "flex",
        flexDirection: "row",
        gap: "32px",
      },
    },
  },
  "@media(max-width:1020px)": {
    footer: {
      gap: "20px",
      // padding: '50px 20px'
    },
    socialContainer: {
      maxWidth: "180px",
    },
  },
  "@media(max-width:780px)": {
    footer: {
      flexDirection: "column",
      gap: "40px",
    },
    socialContainer: {
      maxWidth: "100%",
    },
    container: {
      flexDirection: "column",
      gap: "40px",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="false" style={{ padding: 0 }}>
      <footer className={classes.footer}>
        <div className={classes.socialContainer}>
          {FOOTER_SOCIAL_LINKS.map((item, index) => (
            <div key={index}>
              <img src={item.img} alt="GOPASSGO" width={120} height={80} />
              <p style={{fontSize:"14px"}}>{item.description}</p>
              <div>
                {item.social_links.map((item, index) => (
                  <Link to={item.link} key={index}>
                    <img
                      src={item.icon}
                      alt={item.link}
                      width={32}
                      height={32}
                    />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={classes.container}>
          {FOOTER_LINKS.map((item, index) => (
            <div key={index} className={classes.linksContainer} >
              <p>{item.title}</p>
              {item.links.map((links,index) => (
                <Link to={links.link} className={classes.linksContainer} key={index}>
                  {links.subtitle}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </footer>
    </Container>
  );
};
export default Footer;
