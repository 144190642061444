import { getApiOrigin } from "./context";
import * as utils from "./utilities";
export default class WorksiteService {
  constructor(headers) {
    this.headers = headers;
  }
  async add(worksite) {
      utils.log.api("WorksiteService().add(worksite)", worksite);
      const remoteUrl = `${getApiOrigin()}/worksite/add`;
      const response = await fetch(remoteUrl, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(worksite),
      });
      if (!response.ok) {
        const errorDetail = await response.json();
        throw new Error(
          errorDetail?.detail || "Error occur while adding Worksite"
        );
      }
  }
  async get_old_082523(id) {
    utils.log.api(`worksite.get(id: ${id})`);
    const remoteUrl = `${getApiOrigin()}/worksite/ById/${id}?viewModel=Organization.ViewModels.Worksite`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }
  async get(id) {
    try {
      utils.log.api(`worksite.get(id: ${id})`);
      const remoteUrl = `${getApiOrigin()}/worksite/ById/${id}?viewModel=Organization.ViewModels.Worksite`;

      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch worksite data");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      utils.log.error(error);
      throw error;
    }
  }

  async getAll() {
    utils.log.api("worksite.getAll()");
    const remoteUrl = `${getApiOrigin()}/worksite?viewModel=Organization.ViewModels.Worksite`;

    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      utils.log.error("Error fetching data:", error);
    }
  }

  async update(worksite) {
    utils.log.api("worksite.update", worksite);
    const remoteUrl = `${getApiOrigin()}/worksite`;

    return await fetch(remoteUrl, {
      method: "PATCH", // TODO: Change to PUT // RPL082523 Other similar methods use POST
      headers: this.headers,
      body: JSON.stringify(worksite),
    });
  }
  async remove(id) {
    utils.log.api(`worksite.remove(${id})`);
    const remoteUrl = `${getApiOrigin()}/worksite/ById/${id}`;

    return await fetch(remoteUrl, {
      method: "DELETE",
      headers: this.headers,
    });
  }
}
