/*
ISSUES

*/

// PURPOSE: Allow user to comment in question
import { Typography, Grid, Checkbox, FormControlLabel } from "@mui/material";
import Input from "../../components/Input/inputs";

export function CommentEditView({ question }) {
  // #region Initialize

  // #endregion
  // #region Events
  const handleAllowCommentChange = (event) => {
    // Set question.isCommentAllowed to true/false
    question.isCommentAllowed = event.target.checked;
  };
  // #endregion
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={question.isCommentAllowed}
            onChange={handleAllowCommentChange}
          />
        }
        label="Allow comment"
      />
      <Input
        label="Label"
        defaultValue="Other"
        style={{ width: "120px" }}
      ></Input>{" "}
      <Input
        defaultValue="255"
        style={{ width: "120px" }}
        label="Characters(max)"
      ></Input>
    </>
  );
}
export function CommentRecipientView(props) {
  // #region Initialize
  const { question, setInputs } = props;
  // #endregion
  // #region Events
  const handleChange = (event) => {
    const value = event.target.value;
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: value,
    }));
  };
  // #endregion
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom>Comment:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          multiline
          rows={4}
          name="comment"
          onChange={handleChange}
          value={question.comment}
        />
      </Grid>
    </Grid>
  );
}
/*
FIXED

*/
