import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Grid from "../../components/Grid";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { useSnackbar } from "notistack";

export default function Detail({
  surveyCode,
  recipient,
  mode = "update",
  handlAddClose,
}) {
  utils.log.component(
    `Recipient.Detail(surveyCode: ${surveyCode},recipient: ${recipient?.uid})`,
    recipient
  );

  // #region Assertions
  utils.assert(surveyCode != null, "Survey code required.");
  utils.assert(recipient != null, "Recipient required.");
  //utils.assert(handlAddClose != null, "handlAddClose required.");

  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const [contactMethods, setContactMethods] = useState([]);
  const [worksites, setWorksites] = useState([]);
  const [departments, setDepartments] = useState([]);
  /*eslint-disable-next-line*/
  const [surveyList, setSurveyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  /*eslint-disable-next-line*/
  const [isDeleting, setIsDeleting] = useState(false);
  const [form, setForm] = useState(recipient);
  const { enqueueSnackbar } = useSnackbar();

  const isAddForm = mode === "add";
  const {
    recipientService,
    worksiteService,
    departmentService,
    surveyService,
  } = useContext(AuthContext);
  const getWorksites = async () => {
    const list = await worksiteService.getAll();
    setWorksites(list);
    utils.log.stateChange("setWorksites()", list);
  };

  const getDepartments = async (worksite) => {
    const response = await departmentService.getByWorksite(worksite);
    const list = await response.json();
    const items = utils.toSelectItem(list, "name", "code");
    setDepartments(items);
  };

  const getContactMethods = async () => {
    const list = await recipientService.getContactMethods();
    setContactMethods(list);
    utils.log.stateChange("setContactMethods()", list);
  };

  const getSurveyList = async () => {
    try {
      /*       const result = await surveyService.getAll();
      const surveyResults = await result.json(); 
      setSurveyList(surveyResults);*/

      const list = await surveyService.getAll();
      setSurveyList(list);
    } catch (error) {
      utils.log.error(error);
    }
  };
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("Initialize");

    /*     const getWorksites = async () => {
      const list = await worksiteService.getAll();
      setWorksites(list);
      utils.log.stateChange("setWorksites()", list);
    }; */

    getContactMethods();
    getWorksites();
    getSurveyList();
  }, []);

  useEffect(() => {
    if (!form.worksite) return;
    getDepartments(form.worksite);
  }, [form.worksite]);

  const handleComment = async () => {};

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handlePrimaryAction = async () => {
    setIsLoading(true);
    utils.log.info(":: Primary Action", recipient);
    const requestBody = {
      surveyCode: surveyCode,
      departmentCode: form.department,
      uid: form.uid,
      firstName: form.firstName,
      lastName: form.lastName,
      occupation: form.occupation,
      accessCode: form.accessCode,
      phone: form.phone,
      email: form.email,
      contactMethod: form.contactMethod,
    };

    if (isAddForm) {
      const response = await recipientService.add(requestBody);
      if (response.ok) {
        handlAddClose && handlAddClose();
        enqueueSnackbar("Recipient added", { variant: "success" });
        setForm({});
      } else {
        const data = await response.json();
        const errorMessage = data?.detail || "Failed to add recipient";
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await recipientService.remove(form.uid, surveyCode);
      if (response.ok) {
        enqueueSnackbar("Recipient deleted", { variant: "success" });

        return;
      }
    } catch (error) {
      enqueueSnackbar("Failed to delete recipient", { variant: "error" });
    }
    setIsDeleting(false);
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormSelect
          label="Worksite"
          name="worksite"
          value={form.worksite}
          onChange={handleFormChange}
          fullWidth
          data={utils.toSelectItem(worksites, "name", "code")}
        ></FormSelect>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormSelect
          label="Department"
          name="department"
          onChange={handleFormChange}
          value={form.department}
          fullWidth
          data={departments}
          disabled={!form.worksite}
        ></FormSelect>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Input
          label="First Name"
          fullWidth
          disabled={!isAddForm}
          name="firstName"
          value={form.firstName}
          onChange={handleFormChange}
        ></Input>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Last Name"
          fullWidth
          disabled={!isAddForm}
          name="lastName"
          value={form.lastName}
          onChange={handleFormChange}
        ></Input>
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Occupation"
          fullWidth
          disabled={!isAddForm}
          name="occupation"
          value={form.occupation}
          onChange={handleFormChange}
        ></Input>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Input
          label="Email"
          fullWidth
          name="email"
          value={form.email}
          onChange={handleFormChange}
        ></Input>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          label="Phone"
          fullWidth
          name="phone"
          value={form.phone}
          onChange={handleFormChange}
        ></Input>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          label="Contact Method"
          fullWidth
          data={utils.toSelectItem(contactMethods)}
          name="contactMethod"
          value={form.contactMethod}
          onChange={handleFormChange}
        ></FormSelect>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Access Code"
          fullWidth
          name="accessCode"
          value={form.accessCode}
          onChange={handleFormChange}
        ></Input>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Uid"
          fullWidth
          name="uid"
          disabled={!isAddForm}
          value={form.uid}
          onChange={handleFormChange}
        ></Input>
      </Grid>

      <Grid item xs={4}>
        {!mode && (
          <Button fullWidth onClick={handleComment}>
            Comments
          </Button>
        )}
      </Grid>

      <Grid item xs={4}>
        {!mode && (
          <Button fullWidth onClick={handleDelete}>
            Delete
          </Button>
        )}
      </Grid>
      <Grid item xs={3} sx={{ display: "flex", gap: "10px" }}>
        {handlAddClose && (
          <Button fullWidth onClick={handlAddClose}>
            Cancel
          </Button>
        )}
        {mode && (
          <Button fullWidth onClick={handlePrimaryAction} disabled={isLoading}>
            {isAddForm ? "Add" : "Update"}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
