import { makeStyles } from "@mui/styles";
import { useContext, useEffect } from "react";
import { useRef } from "react";
import * as utils from "../../services/utilities";
import { AppContext } from "../../App";
import orgPeople from "../../assets/organization_people.svg";
import GoPassGoIcon from "../../assets/GoPassGo.png";
import { Box, Divider, Stack } from "@mui/material";
import Item from "antd/es/list/Item";

const useStyles = makeStyles({
  sidebar: {
    width: "240px",
    top: "74px",
    height: "calc(100vh - 74px)",
    background: "#FFFFFF",
    borderRight: "1px solid #d7d7d7",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    paddingLeft: "20px",
    paddingTop: "5px",
    paddingRight: "20px",
    position: "fixed",
    left: 0,
    botton: 0,
  },
  selectedOption: {
    color: "#070707",
    fontWeight: "600",
    lineHeight: "18px",
    textTransform: "capitalize",
  },
  organisationSelector: {
    border: "1px solid #D6D6D6",
    padding: "4px 10px 4px 4px",
    borderRadius: "8px",
    display: "flex",
    height: "51px",
    width: "auto",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
    background: "#E9E9E9",
    cursor: "pointer",
    "@media (min-width: 767px)": {
      display: "none",
    },
  },
  textSwitch: {
    color: "#07070780",
    fontWeight: "600",
    lineHeight: "18px",
    paddingBottom: "2px",
    fontSize: "12px",
  },
  dividerClasess: {
    marginTop: "20px",
    "@media (min-width: 767px)": {
      display: "none",
    },
  },
  sidebarFooter: {
    background: "#2D3338",
    height: "84px",
    width: "240px",
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  sidebarFooterTitle: {
    color: "white",
    margin: "5px",
    fontSize: "12px",
  },
  listStyle: {
    listStyle: "none",
  },
  "@media (max-width:767px)": {
    sidebar: {
      width: "inherit",
      zIndex: "50",
    },
    sidebarFooter: {
      display: "none",
    },
  },
});

export const SideBar = ({ tabs }) => {
  utils.log.component("SideBar");
  // #region Initialize
  const sidebarRef = useRef();
  const classes = useStyles();
  const {employee,  navBarToggler, setNavBarToggler } = useContext(AppContext);
  // #endregion

  // #region Functions
  const handleClickOutside = (event) => {
    if (
      navBarToggler &&
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target)
    ) {
      setNavBarToggler(false);
    }
  };
  // #endregion

  // #region Events
  useEffect(() => {
    if (navBarToggler) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [navBarToggler]);
  // #endregion

  utils.log.info(":: Tabs in sidebar",tabs);

  return (
    <div
      ref={sidebarRef}
      className={`${classes.sidebar} ${
        navBarToggler ? classes.sidebarSecondary : ""
      }`}
    >
      <div className={` ${classes.organisationSelector}`}>
        <Stack
          direction={"row"}
          spacing={3}
          sx={{
            cursor: "pointer",
            padding: "16px 10px ",
          }}
        >
          <Item className={classes.listStyle}>
            <img
              src={orgPeople}
              alt="GoPassGo Networks, LLC"
              width="34"
              height="34"
            />
          </Item>

          <Item className={classes.listStyle}>
            <Stack direction={"column"} sx={{ height: "full" }}>
              <Box className={classes.textSwitch}>Switch Organization :</Box>
              <Box className={classes.selectedOption}>
                {employee?.organization?.legalName}
              </Box>
            </Stack>
          </Item>

          <Item className={classes.listStyle}>
            <Stack direction={"column"} spacing={-2}>
              <Box sx={{ fontWeight: "bold", color: "black" }}>^</Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  transform: "rotate(-180deg)",
                  color: "black",
                }}
              >
                ^
              </Box>
            </Stack>
          </Item>
        </Stack>
      </div>

      <Divider className={classes.dividerClasess} />

      {tabs}

      <div className={classes.sidebarFooter}>
        <p className={classes.sidebarFooterTitle}>Powered By</p>
        <img
          src={GoPassGoIcon}
          alt="GoPassGo-Icon"
          height={"19px"}
          width={"133px"}
        />
      </div>
    </div>
  );
};
