import Button from "../Button";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const HeaderActions = ({ text = "Add", onAdd ,disabled = false }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Button
      size="medium"
      onClick={onAdd}
      startIcon={!isSm && <AddIcon />}
      variant="contained-primary"
      style={{borderRadius:"4px"}}
      disabled={disabled}
    >
      {isSm ? <AddIcon /> : isMd? "Add" : text}
    </Button>
  );
};
