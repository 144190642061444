/*eslint-disable*/
import React, { useContext, useState, useEffect } from "react";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import * as config from "../../services/config";
import DashboardLayout from "../../components/DashboardLayout";
import { HeaderActions } from "../../components/HeaderActions";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Button from "../../components/Button";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
  container: {
    display: "flex",
    gap: "50px",
    marginBottom: "25px",
    flexWrap:"wrap",
    [theme.breakpoints.down("sm")]:{
      flexDirection:"column"
    }
  },
  containerBox: {
    border: "1px solid #ddd",
    width: "300px",
    borderRadius: "10px",
    padding: "10px",
    background: " #fff",
    [theme.breakpoints.down("sm")]:{
      width:"100%"
    }
  },
}));

const columns = [
  { Header: "Order Id", accessor: "orderId" },
  {
    Header: "Order Date",
    accessor: "orderDate",
    Cell: ({ row }) =>
      utils.formatDate(
        row.original.orderDate,
        "ISO",
        config.defaults.DATE_DISPLAY_FORMAT
      ),
  },
  { Header: "Description", accessor: "description" },
  { Header: "Code", accessor: "code" },
  {
    Header: "Amount",
    accessor: "amount",
    align: "right",
    Cell: ({ row }) =>
      row.original.amount
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(row.original.amount)
        : "N/A",
  },
  { Header: "Ordered By", accessor: "orderedBy" },
];

export const Transactions = () => {
  const { planService, surveyService } = useContext(AuthContext);

  const [transactionHistory, setTransactionHistory] = useState(null);
  /*eslint-disable-next-line*/
  const [isLoading, setisLoading] = useState(true);
  const [openTokenPlan, setOpenTokenPlan] = useState(false);
  const [balanceData, setBalanceData] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const fetchTransationsHistory = async () => {
    setisLoading(true);
    try {
      const response = await planService.getTransactions();
      setTransactionHistory(response);
    } catch (error) {
      utils.log.error("Error fetching transactions", error);
    } finally {
      setisLoading(false);
    }
  };
  const fetchBalance = async () => {
    setisLoading(true);
    try {
      const response = await surveyService.getBalance();
      setBalanceData(response);
    } catch (error) {
      utils.log.error("Error in fetching Balance in Transactions", error);
    } finally {
      setisLoading(false);
    }
  };

  const fetchOrder = async () => {
    setisLoading(true);
    try {
      const response = await surveyService.getOrders();
      setOrderData(response);
    } catch (error) {
      utils.log.error("Error in fetch Order", error);
    } finally {
      setisLoading(false);
    }
  };
  useEffect(() => {
    fetchTransationsHistory();
    fetchBalance();
    fetchOrder();
  }, []);

  const headerAction = (
    <HeaderActions text={"Purchase"} onAdd={() => setOpenTokenPlan(true)} />
  );

  const classes = useStyles();
  return (
    <>
      <DashboardLayout
        title="Survey Response Tokens"
        description="Survey Response Tokens are claimed when a recipient either completes a survey or exempts themselves from it."
        button={headerAction}
      >
        <div className={classes.container}>
          <div className={classes.containerBox}>
            <p>Orders(Open)</p>
            <p>{orderData?.length ?? 0}</p>
          </div>
          <div className={classes.containerBox}>
            <p>Total</p>
            <p>{balanceData?.total ?? 0}</p>
          </div>
          <div className={classes.containerBox}>
            <p>Claimed</p>
            <p>
              {balanceData?.claimed !== undefined &&
              balanceData?.pctClaimed !== undefined
                ? `${balanceData.claimed}(${balanceData.pctClaimed}%)`
                : "0(0%)"}
            </p>
          </div>
          <div className={classes.containerBox}>
            <p>Available</p>
            <p>
              {balanceData?.available !== undefined &&
              balanceData?.pctAvailable !== undefined
                ? `${balanceData.available}(${balanceData.pctAvailable}%)`
                : "0(0%)"}
            </p>
          </div>
        </div>
      </DashboardLayout>

      <DashboardLayout
        title="Transaction History"
        description="View transactions for the whole organization below."
      >
        <Table columns={columns} data={transactionHistory} />
        {openTokenPlan && (
          <SurveyResponseToken
            openTokenPlan={openTokenPlan}
            setOpenTokenPlan={setOpenTokenPlan}
          />
        )}
      </DashboardLayout>
    </>
  );
};

const SurveyResponseToken = ({ openTokenPlan, setOpenTokenPlan }) => {
  // #region Assertions
  utils.assert(openTokenPlan, "openTokenPlan is required");
  utils.assert(setOpenTokenPlan, "setOpenTokenPlan is required");
  // #endregion
  // #region Functions
  const fetchSurveyTokenPlans = async () => {
    try {
      const response = await surveyService.getPriceList();
      if (response) {
        setSurveyTokenplans(response);
      }
    } catch (error) {
      utils.log.error(":: Error in fetchSurveyTokenPlans", error);
    } finally {
      setIsLoading(false);
    }
  };
  // #endregion
  // #region Initialize
  const { surveyService } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [surveyTokenPlans, setSurveyTokenplans] = useState(null);

  const columns = [
    {
      Header: "Max Quantity",
      accessor: "maxQuantity",
      align: "center",
      Cell: (cell) => {
        return cell.value ? cell.value.toLocaleString() : "N/A";
      },
    },
    {
      Header: "Unit/Price",
      accessor: "unitPrice",
      align: "right",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    /* { Header: "Description", accessor: "description" }, */
    {
      Header: "Discount",
      accessor: "discountAmt",
      align: "right",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    {
      Header: "Discount(%)",
      accessor: "discountPct",
      align: "right",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "percent",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    {
      Header: "Total price",
      accessor: "totalPrice",
      align: "right",
      Cell: ({ cell }) =>
        cell.value
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cell.value)
          : "N/A",
    },
    {
      Header: "Action",
      accessor: "lookupKey",
      align: "center",
      Cell: ({ row }) => {
        const isRowLoading = loading === row?.values?.lookupKey;
        return (
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            onClick={() => handleBuy(row)}
            disabled={isRowLoading}
          >
            {isRowLoading ? "Loading..." : "Buy"}
          </Button>
        );
      },
    },
  ];
  // #endregion
  // #region Events
  useEffect(() => {
    fetchSurveyTokenPlans();
  }, []);
  const handleBuy = async (row) => {
    setLoading(row.original.lookupKey);
    try {
      const { values } = row;
      const { maxQuantity } = values;
      const payload = {
        productCode: "SurveyResponseToken",
        quantity: maxQuantity,
        ReturnUrl: window.location.origin,
      };

      const response = await surveyService.purchase(payload);
      utils.log.info(":: Survey Response", response);
      if (response) {
        window.open(response?.redirect, "_blank");
      }
    } catch (error) {
      utils.log.error(":: Error in handleBuy()");
      enqueueSnackbar(error?.message, { variant: "errror" });
    } finally {
      setLoading(false);
    }
  };
  // #endregion

  return (
    <Dialog
      open={Boolean(openTokenPlan)}
      onClose={() => setOpenTokenPlan(false)}
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { height: "800px" } }}
    >
      <DialogTitle>
        <h5>Survey Response Tokens</h5>
        <p style={{ fontSize: "16px" }}>
          Unleash the power of your survey data without breaking the bank. Our
          pay-per-response model ensures you only invest in what delivers
          results. Every survey completion or exemption consumes a token,
          unlocking valuable insights to drive your business forward. Reap the
          rewards of our volume-based pricing and watch your costs plummet as
          your token purchases grow.
        </p>
      </DialogTitle>
      <DialogContent>
        <Table
          columns={columns}
          data={surveyTokenPlans}
          isLoading={isLoading}
          hideFilter
          hidePagination
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenTokenPlan(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
