
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

export default function DialogBox({open,title,bodyTitle,body,footer,primaryButtonText="Try Again",secondaryButtonText,handlePrimaryButtonClick ,handleSecondaryButtonClick}) {


  return (
    <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} >
      <DialogTitle sx={{ m: "auto", p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent dividers>
        <CenterDialog>
          <BootstrapDialogTitle gutterBottom>
            {bodyTitle}
          </BootstrapDialogTitle>
        </CenterDialog>
        {body && <BootstrapDialogBody gutterBottom>
          {body}
        </BootstrapDialogBody>}

        <BootstrapDialogFooter gutterBottom>
          {footer}
        </BootstrapDialogFooter>
      </DialogContent>

      <DialogActions>
        <StyledButton variant="outlined" onClick={handlePrimaryButtonClick} >
          {primaryButtonText}
        </StyledButton>
        {secondaryButtonText && <StyledButton variant="outlined" onClick={handleSecondaryButtonClick} disabled={secondaryButtonText === "Logout" ? false : true} >
          {secondaryButtonText}
        </StyledButton>}
      </DialogActions>
    </BootstrapDialog>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}));

const BootstrapDialogTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "600",
  display: "inline-block",
}));

const BootstrapDialogBody = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  padding: "40px 40px 10px 40px",
}));

const BootstrapDialogFooter = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  padding: "5px 60px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "black",
  border: "1px solid black",
}));

const CenterDialog = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
