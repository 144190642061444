import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BlogCard } from "../blog/blogCard";
import { blogData } from "../blog/data";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px",
  },
  wrapper: {
    padding: "100px 0px",
  },
  backLink: {
    color: "#007BFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]:{
      padding:"20px"
    },
    [theme.breakpoints.down("sm")]:{
      padding:"20px"
    }
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    [theme.breakpoints.down("md")]:{
      flexDirection:"column",
      padding:"20px",
      gap:"20px"
    }
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]:{
      textAlign:"center",
    },
    [theme.breakpoints.down("sm")]:{
      width:"300px",
      margin:"auto"
    }
  },
  blogImage: {
    borderRadius: "10px",
    height: "200px",
    // [theme.breakpoints.down("sm")]:{
    //   display:"none"
    // },
    [theme.breakpoints.down("md")]:{
      width:"400px",
      margin:"auto"
    },
    [theme.breakpoints.down("sm")]:{
      width:"300px",
      margin:"auto"
    }
  },
  blogContent: {
    marginTop: "40px",
    padding: "0 80px",
    fontSize: "19px",
    lineHeight: "1.6",
    [theme.breakpoints.down("md")]:{
      textAlign:"center",
      padding:"20px"
    },
    [theme.breakpoints.down("sm")]:{
      textAlign:"center",
      padding:"10px"
    }
  },
  continueReadingSection: {
    padding: "50px 0px",
  },
  continueReadingText: {
    fontSize: "18px",
    fontWeight: "600",
  },
  relatedBlogs: {
    display: "flex",
    gap: "20px",
    paddingTop: "15px",
  },
  blogCardContainer:{
    width:"400px"
  }
}));

export const BlogDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const blogSlug = useParams()?.id;
  const blogDetails = blogData?.find((blog) => blog?.slug === blogSlug);

  return (
    <Container className={classes.container}>
      <div className={classes.wrapper}>
        <span className={classes.backLink} onClick={() => navigate("/blog")}>
          Back to resources
        </span>
        <div className={classes.headerSection}>
            <h3 className={classes.title}>{blogDetails?.title}</h3>
            <img
              src={blogDetails?.image}
              className={classes.blogImage}
              alt={blogDetails?.title}
            />
        </div>
        <div
          className={classes.blogContent}
          dangerouslySetInnerHTML={{ __html: blogDetails?.description }}
        />
        <div className={classes.continueReadingSection}>
          <span className={classes.continueReadingText}>
            Continue Reading...
          </span>
          <div className={classes.relatedBlogs}>
            {blogData
              ?.filter((blog) => blog?.slug !== blogSlug)
              ?.map((item) => (
                <div className={classes.blogCardContainer}>
                <BlogCard item={item} key={item.slug} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Container>
  );
};
