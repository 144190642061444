import { Container } from "@mui/material";
import { HomePageButtons, HomePageFeatureList } from "./Home";

export default function Features() {
  return(
    <>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "150px" }}
      >
        <h2 style={{textAlign:"center",paddingBottom:"20px"}}>Commuter Survey Tool that works for you.</h2>
        <HomePageButtons/>
        <HomePageFeatureList/>
      </Container>
      </>
  );
}
