import { getApiOrigin } from "./context";
import * as utils from "../services/utilities";

export default class NoticeService {
  constructor(headers) {
    this.headers = headers;
  }

  // get
  async get(id) {
    utils.log.api(`notice.get(${id})`);
    utils.assert(id, "id required");

    const remoteUrl = `${getApiOrigin()}/notice/${id}`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    const notice = await response.json();
    utils.log.debug(`notice.get(${id}) result`, notice);
    return notice;
  }
  // getBySection
  async getBySection(section) {
    utils.log.api(`notice.getBySection(${section})`);
    utils.assert(section, "section required");

    const remoteUrl = `${getApiOrigin()}/notice/GetBySection/${section}`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    const notices = await response.json();
    utils.log.debug(`notice.getBySection(${section}) result`, notices);
    return notices;
  }
  // add
  async add(notice) {
    utils.log.api(`notice.add(${notice.code})`, notice);
    utils.assert(notice, "notice required");
    const remoteUrl = `${getApiOrigin()}/notice/add`;
    const response = await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(notice),
    });
    const result = await response.json();
    utils.log.debug(`notice.add(${notice.code}) result`, result);
    return result;
  }
  // update
  // remove

  // acknowledgeCoordinator
  // acknowledgeRecipient

  // responsesCoordinator
  // responsesRecipient
}
