import { getApiOrigin } from "./context";
import * as utils from "./utilities";
import SurveyService from "./survey";

export default class RecipientService {
  constructor(headers, uid, accessCode) {
    this.headers = headers;
    this.uid = uid;
    this.accessCode = accessCode;
  }
  async getByUid(uid, surveyCode) {
    utils.log.api(`recipient.getByUid(${uid})`);

    const remoteUrl = `${getApiOrigin()}/recipient/ByUid/${uid}?surveyCode=${surveyCode}`;

    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) throw new Error("Failed to fetch worksite data");

    return await response.json();
  }

  async getByDepartment(departmentCode, surveyCode) {
    utils.log.api(
      ` :: recipient.getByDepartment(departmentCode: ${departmentCode}, surveyCode: ${surveyCode})`
    );
    utils.assert(departmentCode != null, "departmentCode required");
    utils.assert(surveyCode != null, "surveyCode required");
    const remoteUrl = `${getApiOrigin()}/recipient/ByDepartment/${departmentCode}?surveyCode=${surveyCode}`;
    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });
      if (!response.ok) throw new Error("Failed to fetch recipient data");
      const recipients = await response.json();
      return recipients;
    } catch (error) {
      utils.log.error(
        `RecipientService.getByDepartment Error: ${error.message}`
      );
    }
  }
  async getContactMethods() {
    utils.log.api(`recipient.getContactMethods()`);
    const remoteUrl = `${getApiOrigin()}/recipient/contactMethods`;

    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) throw new Error("Failed to fetch contact methods");

    return await response.json();
  }
  async getSurvey(uid, accessCode, surveyCode) {
    utils.log.api(`recipient.getSurvey(surveyCode: ${surveyCode}`);
    utils.assert(uid != null, "uid required");
    utils.assert(accessCode != null, "accessCode required");
    utils.assert(surveyCode != null, "Survey code required.");
    try {
      const remoteUrl = `${getApiOrigin()}/recipient/survey/${surveyCode}/uid/${uid}/accessCode/${accessCode}`;
      const response = await fetch(remoteUrl, {method: "GET"});
     
      if (!response.ok) {
        const errorDetail = await response.json();
        throw new Error(errorDetail?.detail ||
          `Failed to fetch survey: ${response.status} ${response.statusText}`
        );
      } 
      const survey = await response.json();
      const surveyService = new SurveyService(null);
      surveyService.surveyPrepare(survey);
      return survey;

     } catch (error) {
        utils.log.error(`RecipientService.getSurvey Error: ${error.message}`);
        throw error 
    }
  
  }
  async getExemptions(uid, accessCode) {
    utils.log.api(
      `recipient.getExemptions(uid: ${uid}, accessCode: ${accessCode})`
    );
    utils.assert(uid != null, "uid required.");
    utils.assert(accessCode != null, "accessCode required.");

    const remoteUrl = `${getApiOrigin()}/recipient/exemption/GetAll?uid=${
      uid ?? ""
    }&accessCode=${
      accessCode ?? ""
    }&viewModel=Survey.ViewModels.Recipient.Exemption`;

    const response = await fetch(remoteUrl, {
      method: "GET",
      //headers: this.headers,
    });
    const exemptions = await response.json();
    utils.log.debug(
      `recipient.getExemptions(uid: ${uid}, accessCode: ${accessCode}) result`,
      exemptions
    );
    return exemptions;
  }
  async recipientExemption(body) {
    // NOTE: This is meant for the recipient to exempt themselves, so they are not within a security context.
    const endpoint = `${getApiOrigin()}/exemption/${
      body.exemptionCode
    }/survey/${body.surveyCode}/recipient?uid=${body.recipient}&accessCode=${
      body.accessCode
    }`;

    return await fetch(endpoint, {
      method: "PATCH",
      //headers: this.headers,
    });
  }
  async completed(survey) {
    utils.log.api(`recipient.completed(survey: ${survey}`);
    utils.assert(
      survey != null,
      "Survey required.  Unable to complete survey."
    );
    const remoteUrl = `${getApiOrigin()}/recipient/survey/completed`;

    return await fetch(remoteUrl, {
      method: "POST",
      body: JSON.stringify(survey),
      headers: { "Content-Type": "application/json" },
    });
  }

  async setSurveyManualComplete(uid, surveyCode) {
    utils.log.api(
      `recipient.setSurveyManualComplete(uid: ${uid}, surveyCode: ${surveyCode})`
    );
    const remoteUrl = `${getApiOrigin()}/recipient/${uid}/completed?surveyCode=${surveyCode}`;

    return await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
    });
  }
  async resetSurvey(uid, surveyCode) {
    utils.log.api(
      `recipient.resetSurvey(uid: ${uid}, surveyCode: ${surveyCode})`
    );
    const remoteUrl = `${getApiOrigin()}/recipient/${uid}/reset?surveyCode=${surveyCode}`;
    return await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
    });
  }
  async update(recipient) {
    debugger;
    utils.log.api("recipient.update(recipient)", recipient);
    const remoteUrl = `${getApiOrigin()}/recipient/update`;
    utils.log.info(JSON.stringify(recipient));
    return await fetch(remoteUrl, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(recipient),
    });
  }
  async remove(uid, surveyCode) {
    utils.log.api(`recipient.remove(${uid})`);
    const remoteUrl = `${getApiOrigin()}/recipient/ByUid/${uid}?surveyCode=${surveyCode}`;

    return await fetch(remoteUrl, {
      method: "DELETE",
      headers: this.headers,
    });
  }

  async submitSurvey({ surveyCode, uid, accessCode, body }) {
    utils.log.api(
      `recipient.submitSurvey(survey: ${surveyCode}, uid: ${uid}, accessCode: ${accessCode}, body: ${body})`
    );
    const remoteUrl = `${getApiOrigin()}/survey/${surveyCode}/recipient/${uid}/complete?accessCode=${accessCode}`;

    return await fetch(remoteUrl, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
  }
  async add(requestBody) {
    utils.log.api("recipient.add()");
    const remoteUrl = `${getApiOrigin()}/recipient/add`;

    return fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(requestBody),
    });
  }

  async getIssues(surveyCode) {
    utils.log.api(`recipient.getIssues(surveyCode: ${surveyCode})`);
    const remoteUrl = `${getApiOrigin()}/recipient/issues?surveyCode=${surveyCode}&forceFail=true`;
    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });
      if (!response.ok) throw new Error("Failed to fetch issues");
      const issues = await response.json();
      return issues;
    } catch (error) {
      utils.log.error(`RecipientService.getIssues Error: ${error.message}`);
    }
  }
}
