import React from "react";
import loading from "../assets/loading.gif";

const Loading = () => (
  <div className="spinner">
    <img src={loading} width={50} height={50} alt="Loading" />
    <span>Please wait...</span>
  </div>
);

export default Loading;
