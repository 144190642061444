import {
  ActionCell,
  FilterInput,
  Pagination,
  StyledTable,
  TableBody,
  TableHeader,
  TableHeaderCaption,
  TableRow,
  TableWrapper,
  ThContentWrapper,
  ThIcon,
  ThTitle,
  ThWrapper,
  TableHeaderContent,
  TableContainer,
  FilterInputContainer,
  Select,
  IconButtons,
  IconButtonDown,
  ArrowIcon,
  PageDetails,
} from "./style";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PropTypes from "prop-types";
import SearchIcon from "../../../src/assets/searchIcon.svg";
import { Skeleton } from "@mui/material";

function NextPageButton(props) {
  return (
    <ArrowIcon>
      <IconButton
        disableRipple={true}
        sx={{
          padding: "8px 3px",
          outline: "none",
          "&:focus": {
            outline: "none",
          },
        }}
        {...props}
      >
        <NavigateNextIcon />
      </IconButton>
    </ArrowIcon>
  );
}
function BeforePageButton(props) {
  return (
    <ArrowIcon>
      <IconButton
        disableRipple={true}
        sx={{
          padding: "8px 3px",
          "&:focus": {
            outline: "none",
          },
        }}
        {...props}
      >
        <NavigateBeforeIcon />
      </IconButton>
    </ArrowIcon>
  );
}

function TrashButton(props) {
  return (
    <IconButton {...props}>
      <DeleteIcon />
    </IconButton>
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);

function Table({
  columns,
  data,
  hideFilter,
  hidePagination,
  selected = false,
  multiSelect = false,
  onSelected = () => null,
  isLoading = false,
  actions,
  caption,
  isDraggable = false,
  onDragEnd,
  getSelectedRows,
  selectedRows = {},
  headerAction,
}) {
  const tablePageSize = [10, 15, 20, 25];
  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => data || [], [data]);
  const [updatedPages, setUpdatedPages] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    /*eslint-disable-next-line*/
    state: { selectedRowIds, pageIndex, pageSize, ...state },
    toggleAllRowsSelected,
    selectedFlatRows,
    toggleRowSelected,
    /*eslint-disable-next-line*/
    ...rest
  } = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      initialState: {
        hiddenColumns: ["id"],
        selectedRowIds: selectedRows,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (multiSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div onClick={(e) => e.stopPropagation()}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }

      if (actions) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: "actions",
            Header: "Actions",
            width: 50,
            maxWidth: 50,
            minWidth: 50,

            Cell: ({ row }) => (
              <ActionCell>
                {actions.delete && (
                  <TrashButton
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.delete(e, row.values);
                    }}
                  ></TrashButton>
                )}
              </ActionCell>
            ),
          },
        ]);
      }
    }
  );

  useEffect(() => {
    // Deselect all currently selected rows
    Object.keys(selectedRowIds).forEach((rowId) => {
      toggleRowSelected(rowId, false);
    });

    // Select all rows corresponding to Coordinators/Employee
    Object.keys(selectedRows).forEach((rowId) => {
      toggleRowSelected(rowId, true);
    });
  }, [selectedRows, toggleRowSelected]);

  useEffect(() => {
    setUpdatedPages(page);
  }, [page]);

  const formattedSelectedRows = useMemo(() => {
    return selectedFlatRows.map((r) => r.original);
  }, [selectedFlatRows]);

  useEffect(() => {
    getSelectedRows?.(formattedSelectedRows);
  }, [formattedSelectedRows]);

  const RowClick = (row) => {
    if (selected || multiSelect) {
      // toggleAllRowsSelected(false);
      row.toggleRowSelected(true);
      if (onSelected) {
        onSelected(row);
      }
    } else {
      row.toggleRowSelected(!row.isSelected);
    }
  };

  const TableFooter = () =>
    hidePagination ?? (
      <Pagination>
        <BeforePageButton
          onClick={() => previousPage()}
          disable={`${!canPreviousPage}`}
        />
        <PageDetails>
          {pageIndex + 1} of {pageOptions.length}
        </PageDetails>
        <NextPageButton
          onClick={() => nextPage()}
          disable={`${!canNextPage}`}
        />
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {tablePageSize.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Pagination>
    );

  const handleGlobalFilter = (e) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();

    const sourceIndex = e.dataTransfer.getData("text/plain");
    const draggedRow = updatedPages[sourceIndex];

    const updatedRows = updatedPages.filter(
      (_, index) => index !== parseInt(sourceIndex, 10)
    );

    updatedRows.splice(targetIndex, 0, draggedRow);
    setUpdatedPages(updatedRows);
    onDragEnd?.(updatedRows);
  };

  return (
    <TableContainer>
      <TableHeader>
        {caption ? <TableHeaderCaption>{caption}</TableHeaderCaption> : null}

        <TableHeaderContent
          style={{
            justifyContent: hideFilter ? "flex-end" : "space-between",
          }}
        >
          {!hideFilter && (
            <FilterInputContainer>
              <img className="search-icon" src={SearchIcon} alt="Search " />

              <FilterInput
                placeholder="Search ..."
                onChange={(e) => handleGlobalFilter(e)}
              />
            </FilterInputContainer>
          )}

          {headerAction ? headerAction : null}
        </TableHeaderContent>
      </TableHeader>

      <TableWrapper>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                {headerGroup.headers.map((column, headerIndex) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      backgroundColor: "#F8F8F8",
                      color: "#070707",
                      borderLeft: "1px solid #E9E9E9",
                      borderRight: "1px solid #E9E9E9",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontFamily: "Inter",
                      fontWeight: "700",
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                    index={headerIndex}
                  >
                    <ThWrapper>
                      <ThContentWrapper align={column.align || "left"}>
                        <ThTitle
                          style={{
                            textAlign: column.align || "left",
                          }}
                        >
                          {column.render("Header")}
                        </ThTitle>
                        <ThIcon $visible={column.isSorted}>
                          <IconButtons
                            $visible={column.isSorted}
                            asc={column.isSorted ? true : ""}
                          >
                            ▲
                          </IconButtons>
                          <IconButtonDown
                            $visible={column.isSorted}
                            desc={column.isSortedDesc ? true : ""}
                          >
                            ▼
                          </IconButtonDown>
                        </ThIcon>
                      </ThContentWrapper>
                    </ThWrapper>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <TableBody {...getTableBodyProps()}>
            {isLoading ? (
              [...Array(3).keys()].map((key, index) => (
                <TableRow key={index}>
                  {multiSelect && (
                    <td key={index}>
                      <Skeleton />
                    </td>
                  )}
                  {columns.map((column, index) => (
                    <td key={index}>
                      <Skeleton />
                    </td>
                  ))}
                </TableRow>
              ))
            ) : updatedPages.length === 0 ? (
              <TableRow>
                <td colSpan={"100%"} style={{ textAlign: "center" }}>
                  No Data
                </td>
              </TableRow>
            ) : (
              updatedPages.map((row, index) => {
                prepareRow(row);
                return (
                  <DraggableRow
                    row={row}
                    selected={row.isSelected}
                    draggable={isDraggable}
                    onDragStart={(evt) => handleDragStart(evt, index)}
                    onDragOver={handleDragOver}
                    onDrop={(evt) => handleDrop(evt, index)}
                    onClick={() => {
                      selected && RowClick(row);
                    }}
                    key={index}
                    index={index}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          style={{
                            textAlign: cell.column.align || "left",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </DraggableRow>
                );
              })
            )}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      <br />
      {pageOptions.length > 0 && <TableFooter />}
    </TableContainer>
  );
}

const DraggableRow = ({ row, isDraggable, ...rest }) => {
  return (
    <TableRow
      {...row.getRowProps()}
      selected={row.isSelected}
      draggable={isDraggable}
      {...rest}
    >
      {row.cells.map((cell, index) => {
        return (
          <td
            {...cell.getCellProps()}
            key={index}
            style={{
              textAlign: cell.column.align || "left",
              paddingLeft: "25px",
              paddingRight: "25px",
              fontFamily: "Inter",
              fontWeight: "400",
            }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </TableRow>
  );
};

export default Table;

Table.propTypes = {
  actions: PropTypes.exact({
    delete: PropTypes.func,
  }),
};
