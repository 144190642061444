import { getApiOrigin } from "./context";
import * as utils from "./utilities";
export default class ExemptionService {
  constructor(headers) {
    this.headers = headers;
  }
  async add(exemption) {
    utils.log.api(`exemption.add(${exemption.code})`, exemption);
    const remoteUrl = `${getApiOrigin()}/exemption/add`;
    const response = await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(exemption),
    });
    return response;
  }
  async get(code) {
    utils.log.api(`exemption.get(${code})`);
    const remoteUrl = `${getApiOrigin()}/exemption/byCode/${code}?viewModel=Survey.ViewModels.Recipient.Exemption`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
    const exemption = await response.json();

    utils.log.debug(`exemption.get(${code}) result`, exemption);
    return exemption;
  }
  async getAll(surveyCode = null, worksiteCode = null, departmentCode = null) {
    utils.log.api(
      `exemption.getAll(surveyCode: ${surveyCode}, worksiteCode: ${worksiteCode}, departmentCode: ${departmentCode})`
    );
    const remoteUrl = `${getApiOrigin()}/exemption/GetAll?surveyCode=${
      surveyCode ?? ""
    }&worksiteCode=${worksiteCode ?? ""}&departmentCode=${
      departmentCode ?? ""
    }&viewModel=Survey.ViewModels.Recipient.Exemption`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
    const exemptions = await response.json();
    utils.log.debug(
      `exemption.getAll(surveyCode: ${surveyCode}, worksiteCode: ${worksiteCode}, departmentCode: ${departmentCode}) result`,
      exemptions
    );
    return exemptions;
  }
  async remove(code) {
    utils.log.api(`exemption.remove(${code})`);
    const remoteUrl = `${getApiOrigin()}/exemption/byCode/${code}`;
    return await fetch(remoteUrl, {
      method: "DELETE",
      headers: this.headers,
    });
  }
  async update(exemption) {
    utils.log.api(`exemption.update(${exemption.code})`, exemption);
    const remoteUrl = `${getApiOrigin()}/exemption/update`;
    return await fetch(remoteUrl, {
      method: "PATCH", // TODO: Change to PUT
      headers: this.headers,
      body: JSON.stringify(exemption),
    });
  }
  async setRecipientExemptionStatus(uid, surveyCode, exemptionCode) {
    utils.log.info(
      `exemption.setRecipientExemptionStatus(uid: ${uid}, surveyCode: ${surveyCode}, exemptionCode: ${exemptionCode})`
    );
    const remoteUrl = `${getApiOrigin()}/exemption/${exemptionCode}/survey/${surveyCode}/recipient/${uid}`;

    return await fetch(remoteUrl, {
      method: "PATCH", // TODO: Change to PUT
      headers: this.headers,
    });
  }
}
