import React, { useContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ProtectedRoute from "./components/ProtectedRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/profile";
import Coordinators, { CoordinatorsActivate } from "./views/coordinators";
import Worksites from "./views/worksites";
import Recipients from "./views/recipients";
import RecipientSurvey from "./views/recipients/survey";
import Reports from "./views/reports";
import Agencies from "./views/agencies";
import Surveys from "./views/surveys";
import Provisioning from "./views/Provisioning";
import Features from "./views/Features";
import { Pricing } from "./views/Pricing";
import Test from "./views/test";
import history from "./utils/history";
import * as utils from "./services/utilities";
import TrackPageViews from "./utils/trackPageViews";
import initFontAwesome from "./utils/initFontAwesome";
import Order from "./views/billing/order";
import reactManifest from "react-manifest";
import { PaymentFailed, PaymentSuccess } from "./views/billing/payment";
import { AuthContext } from "./services/context";
import { AccessDeniedModal } from "./views/access-denied";
import { ServerDownModal } from "./views/server-down";
import { createContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BlogDetails } from "./views/blogDetails";
import "./App.css";
import { PageNotFound } from "./views/PageNotFound";
import { Blog } from "./views/blog";
import ContactUs from "./views/contactUs";
import UseCasePage from "./views/use-case-page";
const helmetContext = {};
initFontAwesome();
export const AppContext = createContext();

const OPEN_ROUTES = ["/recipients/survey"];

export default function App() {
  utils.log.component("App");

  const [navBarToggler, setNavBarToggler] = useState(false);
  const appContextValue = useMemo(() => {
    return {
      navBarToggler,
      setNavBarToggler,
    };
  }, [navBarToggler, setNavBarToggler]);

  useEffect(() => {
    reactManifest.update(
      {
        name: "GPG: Commuter Survey",
        short_name: "GPG",
        start_url: "index.html",
        display: "standalone",
        orientation: "portrait",
        theme_color: "#000000",
        background_color: "#ffffff",
        icons: [
          {
            src: "favicon.ico",
            sizes: "64x64 32x32 24x24 16x16",
            type: "image/x-icon",
          },
        ],
      },
      "#dynamic-manifest"
    );
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <AppContext.Provider value={appContextValue}>
        <Router history={history}>
          <Routing>
            <LocalizationProvider dateAdapter={AdapterDayjs} />
          </Routing>
        </Router>
        <ServerDownModal />
      </AppContext.Provider>
    </HelmetProvider>
  );
}

function Routing() {
  const location = useLocation();
  const { isAuthenticated, isLoading, error, user } = useAuth0();
  const { isEmployeeLoading } = useContext(AuthContext);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);

  utils.log.component(`Routing(isAuthenticated: ${isAuthenticated})`);

  const isNavBarVisible = useMemo(
    () => !OPEN_ROUTES.includes(location.pathname),
    [location.pathname]
  );

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (user && !user?.email_verified) {
    return <AccessDeniedModal />;
  }

  if (isLoading || isEmployeeLoading) {
    return <Loading />;
  }
  const handleToggleSideBar = () => setIsSideBarOpen((prev) => !prev);

  return (
    <div id="app" className="d-flex flex-column h-100">
      <div className="flex-grow-1">
        <TrackPageViews />
        {isNavBarVisible ? (
          <NavBar
            handleToggleSideBar={handleToggleSideBar}
            isSideBarOpen={isSideBarOpen}
          />
        ) : null}

        <Routes>
          {/* TODO: 092123 Add end point to alias /recipients/survey --> /survey/{surveyCode}/recipient */}
          <Route
            exact="true"
            path="/"
            element={
              <Home
                isSideBarOpen={isSideBarOpen}
                handleToggleSideBar={handleToggleSideBar}
              />
            }
          />
          <Route path="/features" element={<Features />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/use-case" element={<UseCasePage />}/>
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/contact" element={<ContactUs />} />

          <Route path="/pricing" element={<Pricing />} />
          <Route path="/provisioning" element={<Provisioning />} />
          <Route path="/recipients/survey" element={<RecipientSurvey />} />
          <Route
            path="/coordinator/activate"
            element={<CoordinatorsActivate />}
          />
          <Route path="/billing/success" element={<PaymentSuccess />} />
          <Route path="/billing/cancel" element={<PaymentFailed />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/coordinators" element={<Coordinators />} />
            <Route path="/worksites" element={<Worksites />} />
            <Route path="/test" element={<Test />} />
            <Route path="/recipients" element={<Recipients />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/agencies" element={<Agencies />} />
            <Route path="/surveys" element={<Surveys />} />
            <Route path="/order" element={<Order />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>

      {!user && <Footer />}
    </div>
  );
}
