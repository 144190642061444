// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
import { FormHelperText, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import { AuthContext } from "../../services/context";
import FormUtility from "../../services/form";
import { updateQuestionAnswer } from "../../services/question";
import * as utils from "../../services/utilities";

import Debug from "../../services/debug";
import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";
import errorIcon from "../../assets/errorIcons.svg";
const formUtility = new FormUtility();
export const comparators = [
  "equals",
  "less than",
  "greater than",
  "between", // TODO: 062423 - Need to implement UI
  "even", // TODO: 062423 - Need to disable value textfield in DependencySelector.
  "odd", // TODO: 062423 - Need to disable value textfield in DependencySelector.
  "prime number", // TODO: 062423 - Need to disable value textfield in DependencySelector.
];
const listComparators = utils.toSelectItem(comparators);
export function New() {
  utils.log.component("NumericInput.New()");
  return {
    type: "NumericInput",
    code: null,
    title: "New Numeric Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    min: 1,
    max: 10,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  utils.log.component(`NumericInput.Reset(${question.code})`, question);
  utils.assert(question != null, "question is null.");
  question.answer = null;
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  utils.log.component(`NumericInput.Edit(${question.code})`, question);
  // #region Assertions
  utils.assert(question != null, "question is null.");
  // #endregion Assertions
  // #region Initialize
  question.min = question.min < 1 ? 1 : question.min;
  question.max = question.max < 1 ? 10 : question.max;
  formUtility.setDetail(question);

  // #endregion
  // #region Events

  const handleMinChange = (e) => {
    // debugger;
    const value = e.target.value;
    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Minimum must be a number.", { variant: "error" });
    else if (value > question.max) {
      // Valid value
      return enqueueSnackbar("Minimum must be less than maximum.", {
        variant: "error",
      });
    }
    question.min = Number(value);
  };
  const handleMaxChange = (e) => {
    const value = e.target.value;
    const min = question.min;

    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Maximum must be a number.", { variant: "error" });
    else if (value < min) {
      // Greater than max
      enqueueSnackbar("Maximum must be greater than minimum.", {
        variant: "error",
      });
      return null;
    }
    // Valid value
    else {
      question.maxChoices = Number(value);
    }

    question.maxChoices = Number(value);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography id="non-linear-slider" gutterBottom>
            Range:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label="Minimum"
            fullWidth
            name="min"
            onChange={(event) => formUtility.handleChange(event, setInputs)}
            onBlur={handleMinChange}
            value={formUtility.getValue("min")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label="Maximum"
            fullWidth
            name="max"
            onChange={(event) => formUtility.handleChange(event, setInputs)}
            onBlur={handleMaxChange}
            value={formUtility.getValue("max")}
          />
        </Grid>
      </Grid>
    </>
  );
}
export const DependencySelector = ({ context }) => {
  // !NOTE: useState() is prohibited in this component.
  // #region Initialize
  // #endregion
  // #region Events
  // #endregion
  return (
    <>
      <hr></hr>
      <DependencySelectorComparatorValue
        id="value"
        label="Value"
        context={context}
      ></DependencySelectorComparatorValue>
    </>
  );
};
export const DependencySelectorComparatorValue = ({ id, label, context }) => {
  // TODO: Consider using Ant Design InputNumber
  // #region Initialize
  const { cache, error } = useContext(AuthContext);
  const dependency = dependencyUtil.get(context);
  if (dependency != null && dependency[id] == null)
    // Add a value if it doesn't exist
    dependency[id] = null;
  const comparatorId = `${id}-comparator`;
  let comparator = dependency[comparatorId] ?? listComparators[0].value;
  const [errorObject, setErrorObject] = useState(null);
  let valueObject = {
    label: label,
    defaultValue: dependency[id] ?? cache.get(id) ?? null,
    error: errorObject,
  };
  // #endregion
  // #region Events Handlers

  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    dependency[comparatorId] = e.target.value;
    dependencyUtil.set(context, dependency);
    comparator = dependency[comparatorId];
  };
  const handleValueChange = (e) => {
    const value = e.target.value;
    if (value === "" || isNaN(value)) {
      // Invalid number
      error.setErrorObject(id, true, "Invalid number.");
    } else {
      // Valid number
      dependency[id] = value;
      error.clearErrorObject(id);
    }
    setErrorObject(error.getErrorObject(id));
  };
  // #endregion
  return (
    <Grid container spacing="20">
      <Grid item xs={2}>
        {valueObject.label}
      </Grid>
      <Grid item xs={4}>
        <FormSelect
          id={`numericInput-${id}-comparators`}
          label="Comparators"
          data={listComparators}
          defaultValue={comparator}
          style={{ width: "120px" }}
          onChange={handleComparatorChange}
          debug
        ></FormSelect>{" "}
      </Grid>
      <Grid item xs={5}>
        <Input
          id={`numericInput-${id}-value`}
          label={valueObject.label}
          name={id}
          fullWidth
          style={{ width: "120px" }}
          onChange={(event) => handleValueChange(event)}
          defaultValue={valueObject.defaultValue}
          onFocus={(e) => e.currentTarget.select()}
          error={valueObject.error?.state}
          helperText={valueObject.error?.message}
        ></Input>
        <br></br>
      </Grid>
      <Grid item xs={1}>
        <Debug debugging={dependencyUtil.get(context)}></Debug>
      </Grid>
    </Grid>
  );
};
export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.comparator = comparators[0];
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  utils.log.component("NumericInput.Render()");
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  //when form is render initiall answer is not present in qun.So,we set qun.min to answer becaues we apply error on answer
  const answer = question.answer ?? question.min;
  const minValue = question.min ?? 0;
  const maxValue = question.max ?? 0;

  const validationMessage =
    answer < minValue || answer > maxValue
      ? `Value must be between ${minValue} and ${maxValue}.`
      : "";
  // #endregion
  // #region Events
  const handleChange = (e) => {
    let value = Number(e.target.value);
    utils.log.event(`handleChange(e): ${value}`);
    updateQuestionAnswer(question, value, preview, props.setQuestions);
  };
  const isQuestionCompleted = isCompleted(question);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            Q{props.index + 1}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          {/* <div>{helperText}</div> */}
          <TextField
            id="numericInput-value"
            key={`numericInput-value.${question.code}`}
            label={`Enter value Range(${minValue} to ${maxValue})`}
            name="value"
            style={{ 
            width: isSm ? "100%" : "300px", 
            background: "#F8F8F8" }}
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              // min: minValue, // BUG: 062623 min/max not being enforced.
              // max: maxValue,
            }}
            onChange={handleChange}
            // helperText={`${minValue} to ${maxValue}`}
            placeholder={`Enter value Range(${minValue} to ${maxValue})`}
            fullWidth
          ></TextField>
        </div>
      </div>
      {validationMessage && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop:preview ? 0 : "-20px",
          }}
        >
          <img
            src={errorIcon}
            alt="error-icon"
            height={"15px"}
            width={"15px"}
          />
          <FormHelperText style={{ color: "#DB3B3B" }}>
            {validationMessage}
          </FormHelperText>
        </div>
      )}
    </>
  );
}
// #region Dependency

export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  //debugger;

  if (dependency == null) {
    utils.log.info(`NumericInput.isAnswered(false): dependency is null`);
    return false;
  }

  utils.log.info(
    `NumericInput.isAnswered(${dependency.question.code})`,
    dependency
  );

  // *** Evaluate ***
  switch (dependency["value-comparator"]) {
    case "equals":
      return handleComparatorEquals(dependency);
    case "less than":
      return handleComparatorLessThan(dependency);
    case "greater than":
      return handleComparatorGreaterThan(dependency);
    case "between":
      return handleComparatorBetween(dependency);
    case "even":
      return handleComparatorEven(dependency);
    case "odd":
      return handleComparatorOdd(dependency);
    case "prime number":
      return handleComparatorPrimeNumber(dependency);
    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
function handleComparatorEquals(dependency) {
  const result = dependency.value === dependency.question.answer;
  utils.log.info(
    `NumericInput.handleComparatorEquals(${dependency.question.code}): ${dependency.value} equals ${dependency.question.answer} --> ${result}`
  );
  return result;
}

function handleComparatorLessThan(dependency) {
  const result = dependency.question.answer < dependency.value;
  utils.log.info(
    `NumericInput.handleComparatorLessThan(${dependency.question.code}): ${dependency.question.answer} < ${dependency.value} --> ${result}`
  );
  return result;
}

function handleComparatorGreaterThan(dependency) {
  const result = dependency.question.answer > dependency.value;
  utils.log.info(
    `NumericInput.handleComparatorGreaterThan(${dependency.question.code}): ${dependency.question.answer} > ${dependency.value} --> ${result}`
  );
  return result;
}

function handleComparatorBetween(dependency) {
  // TODO: 062423 The calculation method is incorrect.
  const result =
    dependency.value >= dependency.question.min &&
    dependency.value <= dependency.question.max;
  utils.log.info(
    `NumericInput.handleComparatorBetween(${dependency.question.code}): ${result}`
  );
  return result;
}

function handleComparatorEven(dependency) {
  const result = dependency.question.answer % 2 === 0;
  utils.log.info(
    `NumericInput.handleComparatorEven(${dependency.question.code}): ${dependency.question.answer} is even --> ${result}`
  );
  return result;
}

function handleComparatorOdd(dependency) {
  const result = dependency.question.answer % 2 === 1;
  utils.log.info(
    `NumericInput.handleComparatorOdd(${dependency.question.code}): ${dependency.question.answer} is odd --> ${result}`
  );
  return result;
}

function handleComparatorPrimeNumber(dependency) {
  const answer = dependency.question.answer;
  if (answer <= 1) {
    utils.log.info(
      `NumericInput.handleComparatorPrimeNumber(${dependency.question.code}): prime number(false) - value(${answer}) is less than or equal to 1`
    );
    return false;
  }

  // Check for divisibility from 2 to the square root of the number
  for (let divisor = 2; divisor <= Math.sqrt(answer); divisor++) {
    if (answer % divisor === 0) {
      utils.log.info(
        `NumericInput.handleComparatorPrimeNumber(${dependency.question.code}): prime number(false) - value(${answer}) is divisible by ${divisor}`
      );
      return false;
    }
  }
  utils.log.info(
    `NumericInput.handleComparatorPrimeNumber(${dependency.question.code}): prime number(true) - value(${answer}) is prime`
  );
  return true;
}

export const isCompleted = (question) => {
  utils.log.info(":: NumericInput.isCompleted()", question);
  if (!question) {
    utils.log.warn("NumericInput.isCompleted(false): question is null");
    return false;
  }
  // check for min and max
  return question.answer >= question.min && question.answer <= question.max;
};

export const getValue = (question) => {
  return {
    value: question.answer,
  };
};

// #endregion
/*
FIXED

*/
