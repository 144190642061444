import { Grid as MGrid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export default function Grid(props) {
    const { children, ...rest } = props;
    return <MGrid {...rest}>{children}</MGrid>;
}

Grid.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    columns: PropTypes.oneOf([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number,
        PropTypes.object,
    ]),
    columnSpacing: PropTypes.oneOf([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        ),
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
    ]),
    component: PropTypes.elementType,
    container: PropTypes.bool,
    direction: PropTypes.oneOf([
        "column-reverse",
        "column",
        "row-reverse",
        "row",
    ]),
    item: PropTypes.bool,
    xl: PropTypes.oneOfType([
        PropTypes.oneOf(["auto"]),
        PropTypes.number,
        PropTypes.bool,
    ]),
    lg: PropTypes.oneOfType([
        PropTypes.oneOf(["auto"]),
        PropTypes.number,
        PropTypes.bool,
    ]),
    md: PropTypes.oneOfType([
        PropTypes.oneOf(["auto"]),
        PropTypes.number,
        PropTypes.bool,
    ]),
    sm: PropTypes.oneOfType([
        PropTypes.oneOf(["auto"]),
        PropTypes.number,
        PropTypes.bool,
    ]),
    xs: PropTypes.oneOfType([
        PropTypes.oneOf(["auto"]),
        PropTypes.number,
        PropTypes.bool,
    ]),
    sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    wrap: PropTypes.oneOf(["nowrap", "wrap-reverse", "wrap"]),
    spacing: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
    ]),
    zeroMinWidth: PropTypes.bool,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
};