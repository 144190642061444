import BugReport from "@mui/icons-material/BugReport";
import { IconButton } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "./context";

const Debug = (debugging) => {
  const { isDevelopment, cache } = useContext(AuthContext);
  const handleClick = (debugging, cache) => {
    debugger;
  };

  if (!isDevelopment) return null;
  return (
    <IconButton onClick={() => handleClick(debugging, cache)}>
      <BugReport></BugReport>
    </IconButton>
  );
};
export default Debug;
