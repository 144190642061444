import { makeStyles } from "@mui/styles";
import Error from "../../assets/Error.svg";
import Button from "../../components/Button";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#F4F6F8",
    color: "#26262A",
  },
  image: {
    width: "400px",
    marginBottom: "20px",
  },
  errorCode: {
    fontSize: "72px",
    fontWeight: "bold",
    margin: "0",
    color: "#0066F9",
  },
  message: {
    fontSize: "24px",
    fontFamily: '"Inter", sans-serif',
    fontWeight: "600",
    lineHeight: "30px",
    marginTop: "10px",
  },
  subMessage: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  homeButton: {
    fontSize: "16px",
    transition: "background-color 0.3s, transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
});

export const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={Error} alt="Error Illustration" className={classes.image} />

      <h1 className={classes.errorCode}>Page not found!</h1>
      <p className={classes.message}>It seems like you've missed your stop.</p>
      <p className={classes.subMessage}>
        Don't worry, we'll get you back on track!
      </p>
      <Button
        className={classes.homeButton}
        onClick={() => (window.location.href = "/")}
      >
        Take Me to Home 🚀
      </Button>
    </div>
  );
};
