import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import * as utils from "../../services/utilities";

export default function FormSelect({
  label,
  data,
  onChange,
  defaultValue,
  allowNoSelection,
  caseInsensitive,
  debug,
  name,
  ...props
}) {
  if (data == null) {
    utils.log.error("data is required.");
    return null;
  }

  if (debug)
    utils.log.component(
      `FormSelect(label: ${label}) defaultValue: ${defaultValue}`,
      data
    );

  const labelId = `${label}-label`;

  if (allowNoSelection && data.filter((i) => i.value === -1).length === 0)
    data.unshift(allowNoSelection);
  if (debug)
    utils.log.debug("%cFormSelect: ", "color: green;background-color:yellow", {
      label,
      data,
      defaultValue,
    });
  if (allowNoSelection && defaultValue == null)
    defaultValue = allowNoSelection.value;

  const handleChange = (e) => {
    utils.log.event(
      `FormSelect.handleChange(${label}) - value: ${e.target.value} defaultValue: ${defaultValue}`,
      { e, data }
    );
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        name={name}
        value={props.value}
        labelId={labelId}
        label={label}
        defaultValue={
          caseInsensitive ? data[0]?.value.toUpperCase() : data[0]?.value
        }
        onChange={handleChange}
      >
        {data.map((item) => {
          const key = item.value ?? item;
          const value = caseInsensitive
            ? item?.value.toUpperCase() ?? item.toUpperCase()
            : item.value ?? item;
          return (
            <MenuItem value={value} key={key}>
              {item.label ?? item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
};
