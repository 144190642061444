import {  Container, Switch } from "@mui/material";
import React from "react";
import offers from "../assets/offers.svg";
import offersSmallSizeIcon from "../assets/offersSmallSizeIcon.svg";
import { PRICING_DETAILS } from "../utils/contentData";
import Button from "./Button";
import tickIcon from "../assets/tickIcon.svg";
import { makeStyles } from "@mui/styles";
import * as utils from "../services/utilities"
const useStyles = makeStyles(() => ({
  service: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: "20px",
    "& span": {
      fontFamily: "Manrope",
      display: "flex",
      alignItems: "center",
      gap: "17px",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "21.86px",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    padding: "40px 24px 40px 24px",
    gap: "10px",
    maxWidth: "380px",
    background: "white",
    boxShadow: "0px 4px 30px 0px #0000001A",
    "& Button": {
      border: "1.5px solid #1D4ED8",
      color: "#1D4ED8",
      padding: "6px 12px 6px 12px",
      borderRadius: "2px",
      height: "44px",
      fontSize: "14px",
      fontWeight: 700,
      background: "#FFFFFF",
      lineHeight: "17.5px",
      margin: "4px",
      boxShadow: "none",
      textTransform: "math-auto",
      "&:hover": {
        boxShadow: "none",
        background: "#FFFFFF",
      },
    },

    "& h5": {
      color: "#191D23",
      fontSize: "22px",
      fontWeight: 700,
      fontFamily: "Manrope",
      lineHeight: "30.05px",
    },
    "& p": {
      fontFamily: "Quicksand",
      fontWeight: 500,
      color: "#64748B",
      fontSize: "14px",
      lineHeight: "17.5px",
    },
    "& h3": {
      fontFamily: "Quicksand",
      fontWeight: 600,
      color: "#191D23",
      display: "flex",
      fontSize: "44px",
      lineHeight: "55px",
      gap: "4px",
      "& span": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 300,
      },
    },
  },
  main: {
    padding: "0px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Quicksand",
    fontWeight: 600,
    gap: "40px",
    "& section": {
      width: "100%",
      display: "grid",
      marginTop: "30px",
      gap: "20px",
      justifyContent: "center",
      gridTemplateColumns: "auto auto auto",
    },
    "& h2": {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  true: {
    background: "#0B109F",
    color: "white",
    "& h5": {
      color: "white",
    },
    "& p": {
      color: "#F7F8F9",
    },
    "& h3": {
      color: "white",
    },
  },
  cutText: {
    textDecoration: "line-through",
  },

  payment: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    marginBottom: "30px",
  },
  offers: {
    position: "absolute",
    right: "-200px",
    bottom: "-40px",
    display: "flex",
    alignItems: "flex-end",
    "& p": {
      color: "#1D4ED8",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "22.5px",
      fontFamily: "Quicksand ",
    },
  },
  "@media (min-width: 640px)": {
    smallImg: {
      display: "none",
    },
    bigimg: {
      display: "flex",
    },
  },
  "@media (max-width: 1020px)": {
    main: {
      "& section": {
        gridTemplateColumns: "auto",
      },
      gap: "10px",
    },
    card: {
      maxWidth: "100%",
      "& h3": {
        fontSize: "32px",
      },
    },
  },
  "@media (max-width: 640px)": {
    offers: {
      flexDirection: "row-reverse",
      bottom: "-90px",
      right: "40px",
    },
    smallImg: {
      bottom: "10px",
      position: "relative",
      display: "flex",
    },
    bigimg: {
      display: "none",
    },
    payment: {
      marginBottom: "75px",
    },
  },
}));

const PricingCard = ({
  pricingArr = PRICING_DETAILS,
  showAd = true,
  handleClick,
  activePlan,
  handleClickParams,
}) => {

  utils.log.info(":: pricing info",{pricingArr,showAd,handleClick,handleClickParams});
  const classes = useStyles();
  return (
    <Container maxWidth="lg" style={{ padding: 0 }}>
      <div className={classes.main}>
        {showAd && (
          <div>
            <h2>Choose a plan that’s right for you</h2>
            <div>
              <div className={classes.payment}>
                <span>Pay Monthly </span>
                <Switch />
                <span>Pay Annually</span>
                <div className={classes.offers}>
                  <img src={offers} alt="offer" className={classes.bigimg} />
                  <img
                    src={offersSmallSizeIcon}
                    alt="offers"
                    className={classes.smallImg}
                  />
                  <p>save 25%</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <section>
          {pricingArr?.map((item, index) => (
            <div
              key={index}
              className={`${index === 1 ? classes.true : "" } ${classes.card}`}
            >
              <h5>{item.heading}</h5>
              <p>{item.description}</p>
              <h3 className={`${index === 1 ? classes.true : ""}`}>
                {item.price}
                {index !== 2 && <span>/ Month</span>}
                {index === 2 && <span>/ Annually</span>}
              </h3>

              <Button
                size="medium"
                onClick={ handleClickParams ? () => handleClickParams(item) : () => handleClick(item)}
                disabled={activePlan && item?.code === activePlan?.code}
              >
                {activePlan && activePlan?.code === item?.code
                  ? "Selected"
                  : item.btnText}
              </Button>
              {
                <div className={classes.service}>
                  {item.services.map((data, index) => (
                    <span key={index}>
                      <img src={tickIcon} alt="tick" />
                      <span
                        className={
                          data?.service?.includes("Not Included") ?
                          classes.cutText : ""
                        }
                      >
                        {data?.service}
                      </span>
                    </span>
                  ))}
                </div>
              }
            </div>
          ))}
        </section>
      </div>
    </Container>
  );
};

export default PricingCard;
