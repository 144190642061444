import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../services/context";
import { Selector as Dependency } from "./questions/dependencySelector";

export default function Test() {
  const { surveyService } = useContext(AuthContext);
  const [survey, setSurvey] = useState(null);

  if (survey == null) {
    surveyService.get(1).then((response) =>
      response.json().then((result) => {
        setSurvey(result);
      })
    );
  }

  // TODO: OnClose handler
  const [open] = useState(true);

  if (survey == null) {
    return "Loading";
  } else {
    // TODO: Select current question
    const [q2, q4, q6, q9] = survey.questions;
    const q2c2 = q2.choices[1]; // Choice Dependency: Q1.C2

    const contextQ2_C2 = { question: q2, choice: q2c2 };
    const contextQ4 = { question: q4, choice: null };
    const contextQ6 = { question: q6, choice: null };
    const contextQ9 = { question: q9, choice: null };
    //const categoryChoice = survey.questions[2];

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <h1>Visibility Dependency Selector</h1>
        </Grid>
        <Grid item xs={12}>
          <h5>
            Assertions (in order of priority):
            <ol>
              <li>ASSERT: Dependency is optional (select: None)</li>
              <li>ASSERT: Questions exclude self (context.question)</li>
              <li>ASSERT: Questions ordred by question.sort, then by TBD</li>
              <li>
                ASSERT: Dependency Selector renders for Dependency.Question.Type
              </li>
              <li>
                ASSERT: Questions exclude questions later than self
                (context.question)
              </li>
            </ol>
          </h5>
        </Grid>
        <Grid item xs={12}>
          <h5>
            Test: Context: Question(Q6) | Dependency: Question(Q2)
            <ul>
              <li>TODO: Add dependency to Q2</li>
            </ul>
          </h5>
          <Dependency
            onClose={null}
            open={open}
            context={contextQ6}
            questions={survey.questions}
          ></Dependency>
        </Grid>

        <Grid item xs={12}>
          <h5>TODO: Context: Question | Dependency: Choice</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>
            Test: Context: Choice(Q2.C2) | Dependency: Choice(Q1.C2)
            <ul>
              <li>ASSERT: </li>
            </ul>
          </h5>
          <Dependency
            onClose={null}
            open={open}
            context={contextQ2_C2}
            questions={survey.questions}
          ></Dependency>
        </Grid>
        <Grid item xs={12}>
          <h5>TODO: Context: Choice | Dependency: Question</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>TODO: Context: Category Choice | Dependency: Question</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>TODO: Context: Category Choice | Dependency: Choice</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>TODO: Context: Category Choice | Dependency: Category Choice</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>TODO: Context: Question | Dependency: Category Choice</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>TODO: Context: Choice | Dependency: Category Choice</h5>
        </Grid>
        <Grid item xs={12}>
          <h5>
            Test: Context: Question(Q4) | Dependency: (NONE) NOTE:
            <ul>
              <li>
                ASSERT: Questions exclude questions later than self
                (context.question)
              </li>
            </ul>
          </h5>
          <Dependency
            onClose={null}
            open={open}
            context={contextQ4}
            questions={survey.questions}
          ></Dependency>
        </Grid>
        <Grid item xs={12}>
          <h5>
            TODO: Context: Question(Q9) | Dependency: TextInput(Q5)
            <ul>
              <li>ASSERT: Correct selectors for TextInput</li>
            </ul>
          </h5>
          <Dependency
            onClose={null}
            open={open}
            context={contextQ9}
            questions={survey.questions}
          ></Dependency>
        </Grid>
      </Grid>
    );
  }
}
