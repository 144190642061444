export default class Error {
  constructor() {
    this.cache = {};
  }

  createErrorObject(name, state = false, message = null) {
    const errorObject = { name: name, state: state, message: message };
    return errorObject;
  }
  setErrorObject(name, state = false, message = null) {
    const errorObject = this.createErrorObject(name, state, message);
    this.cache = { ...this.cache, [name]: errorObject };
    return errorObject;
  }
  getErrorObject(name) {
    return this.cache[name];
  }
  clearErrorObject(name) {
    if (name === undefined) this.cache = {};
    else {
      delete this.cache[name];
    }
  }
  getErrors() {
    return this.cache;
  }
  hasErrors() {
    return Object.keys(this.cache).length > 0;
  }
}
