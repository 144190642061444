import { makeStyles } from "@mui/styles";

const getStyles = (value) => {
  switch (value) {
    case "NONE":
      return { backgroundColor: "#ff000050", color: "#ff0000" };
    case "EXPIRED":
      return { backgroundColor: "#F7EDDE", color: "#FF7020" };
    case "ACTIVE":
      return { backgroundColor: "#F1FFEA", color: "#28922C" };
    // case "REJECTED":
    //   return { backgroundColor: "#FFEAEA", color: "#FC1818" };
    default:
      return { backgroundColor: "#E9E9E9", color: "black" };
  }
};

const useStyles = makeStyles({
  chip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    backgroundColor: "transparent",
    color: (styles) => styles.color,
    fontSize: "14px",
    fontWeight: 400,
    width: "auto",
    margin: "auto",
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: (styles) => styles.color,
    marginRight: "8px",
  },
  text: {
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    fontWeight: "600",
  },
});

export const Status = ({ value }) => {
  value = value.toUpperCase();
  const styles = getStyles(value);
  const classes = useStyles(styles);

  return (
    <div className={classes.chip}>
      <div className={classes.dot} />
      <div className={classes.text}>{value}</div>
    </div>
  );
};
