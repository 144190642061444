import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import Grid from "../../../components/Grid";
import Table from "../../../components/Table";

import Button from "../../../components/Button";
import * as config from "../../../services/config";
import { AuthContext } from "../../../services/context";
import * as utils from "../../../services/utilities";
import Recipient from "../detail";
import FormSelect from "../../../components/Select";
import Input from "../../../components/Input/inputs";
import { useSnackbar } from "notistack";
import MUIDialog from "../../../components/Modal";

const dateTimeDisplayFormat = `${config.defaults.DATE_DISPLAY_FORMAT} ${config.defaults.HOUR_DISPLAY_FORMAT}`;

export default function Summary({ survey }) {
  utils.log.component(`Recipient.Summary(surveyCode: ${survey.code})`, survey);
  // #region Assertions
  utils.assert(survey != null, "Survey required.");

  const { worksiteService } = useContext(AuthContext);
  const [worksites, setWorksites] = useState([]);
  const [selectedWorksiteCode, setSelectedWorksiteCode] = useState("");

  // #endregion
  // #region Functions
  const getWorksites = async () => {
    try {
      const result = await worksiteService.getAll();
      //const data = await result.json();
      // Sort the data by the 'code' property
      result.sort((a, b) => a.code.localeCompare(b.code));
      setWorksites(result);
      utils.log.stateChange("setWorksites(data)", result);
    } catch (error) {
      // Handle any potential errors here
      utils.log.error("Error updating list:", error);
    }
  };
  // #endregion
  // #region Initialize

  // #endregion
  // #region Events
  useEffect(() => {
    getWorksites();
  }, []);

  const selectedWorksiteInfo = useMemo(
    () => worksites.find((w) => w.code === selectedWorksiteCode),
    [selectedWorksiteCode, worksites]
  );

  // #endregion

  return (
    <div>
      <Grid container style={{ marginBottom: "32px" }}>
        <Grid item xs={12} md={4}>
          <FormSelect
            label="Select worksite"
            value={selectedWorksiteCode}
            onChange={(e) => setSelectedWorksiteCode(e.target.value)}
            data={utils.toSelectItem(worksites, "name", "code")}
            fullWidth
          />
        </Grid>
      </Grid>

      {selectedWorksiteCode && selectedWorksiteInfo ? (
        <Worksite
          selectedWorksiteCode={selectedWorksiteCode}
          worksite={selectedWorksiteInfo}
          survey={survey?.code}
        />
      ) : null}
    </div>
  );
}

export const Worksite = ({ selectedWorksiteCode, worksite, survey }) => {
  utils.log.component(`Recipient.Worksite(${worksite.code})`, worksite);
  // #region Assertions
  utils.assert(worksite != null, "Worksite required.");
  utils.assert(survey != null, "Survey required.");
  // #endregion
  // #region Functions
  const getDepartments = async (worksite) => {
    setIsLoading(true);
    try {
      utils.assert(worksite != null, "Worksite required.");
      const result = await departmentService.getByWorksite(
        worksite.code,
        survey
      );
      const data = await result.json();
      setIsLoading(false);
      // Sort the data by the 'code' property
      data.sort((a, b) => a.code.localeCompare(b.code));
      setDepartments(data);
      utils.log.stateChange("setDepartments(data)", data);
    } catch (error) {
      // Handle any potential errors here
      setIsLoading(false);
      utils.log.error("Error updating list:", error);
    }
  };
  // #endregion
  // #region Initialize
  const { departmentService } = useContext(AuthContext);
  const [departments, setDepartments] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [department, setDepartment] = useState(null);
  const columns = [
    { Header: "Department", accessor: "name" },
    { Header: "Coordinator", accessor: "coordinator" },
    { Header: "Contacted", accessor: "contactedCount", align: "center" },
    { Header: "Started", accessor: "startedCount", align: "center" },
    { Header: "Completed", accessor: "completedCount", align: "center" },
    { Header: "Exempted", accessor: "exemptedCount", align: "center" },
  ];
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect(
      `Recipient.Worksite(${worksite.code}) initialize`,
      worksite
    );
    getDepartments(worksite);
  }, [worksite.code]);
  const handleEdit = (department) => {
    setIsEditOpen(true);
    setDepartment(department);
  };
  const [isLoading, setIsLoading] = useState(false);
  // #endregion
  if (departments == null) return <>- No departments found. -</>;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            caption={`${worksite.code}: ${worksite.name}`}
            columns={columns}
            data={departments}
            selected
            onSelected={(row) => handleEdit(row.original)}
            isLoading={isLoading}
            // multiSelect
          ></Table>
        </Grid>
      </Grid>
      {isEditOpen && (
        <Department
          open={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          department={department}
          survey={survey}
          setDepartment={setDepartment}
        ></Department>
      )}
    </>
  );
};
export const Department = ({
  open,
  onClose,
  department,
  survey,
  setDepartment,
}) => {
  utils.log.component(
    `Recipient.Department(${department.code})`,
    department,
    survey
  );
  // #region Assertions
  utils.assert(department != null, "Department required.");
  utils.assert(survey != null, "Survey required.");
  utils.assert(setDepartment != null, "setDepartment required.");
  // #endregion
  // #region Functions
  const updateList = async () => {
    utils.log.info(`Recipient.Department(${department.code}) updateList()`);
    try {
      const recipients = await recipientService.getByDepartment(
        department.code,
        survey
      );

      setList(recipients);
      utils.log.stateChange("setList(recipients)", recipients);
    } catch (error) {
      // Handle errors here
      utils.log.error("Error updating list:", error);
    }
  };
  // #endregion
  // #region Initialize
  const { departmentService, recipientService } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [list, setList] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [error, setError] = useState(null);
  const columns = [
    { Header: "Recipient", accessor: "fullName" },
    { Header: "UID", accessor: "uid" },
    { Header: "Occupation", accessor: "occupation" },
    /*     { Header: "Phone", accessor: "phone" },
    { Header: "Email", accessor: "email" }, */
    { Header: "Status", accessor: "" },
  ];
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect(
      `INIT: Recipient.Department(${department.code})`,
      department
    );
    updateList();
  }, []);
  const handleEdit = (recipient) => {
    setIsEditOpen(true);
    setRecipient(recipient);
  };
  const handleChange = async (e) => {
    setDepartment((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleUpdate = async () => {
    try {
      setError(null);
      await departmentService.update(department);
      enqueueSnackbar("Department updated.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`ERROR: ${error.message}`, { variant: "error" });
    }
  };
  // #endregion

  return (
    <>
      <MUIDialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        title="Department"
        actions={<Button onClick={onClose}>Close</Button>}
        PaperProps={{ style: { height: "600px" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Input
              label="Code"
              fullWidth
              name="code"
              disabled
              defaultValue={department?.code}
              onChange={handleChange}
            ></Input>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Input
              label="Name"
              fullWidth
              name="name"
              required
              defaultValue={department?.name}
              onChange={handleChange}
            ></Input>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Button onClick={handleUpdate}>Update</Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              {error}
            </Grid>
          )}
        </Grid>

        <div style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Coordinator(s)
            </Grid>
            <Grid item xs={8}>
              {department.coordinator ?? "- Unassigned -"}
            </Grid>
            <Grid item xs={12}>
              <Table
                columns={columns}
                data={list}
                selected
                caption="Recipients"
                onSelected={(row) => handleEdit(row.original)}
              ></Table>
            </Grid>
          </Grid>
        </div>
      </MUIDialog>

      <MUIDialog
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        fullWidth
        maxWidth="lg"
        title="Recipient"
        actions={
          <>
            <Button>Cancel</Button>
            <Button>Update</Button>
          </>
        }
      >
        <RecipientDetails surveyCode={survey} recipient={recipient} />
      </MUIDialog>
    </>
  );
};

export const RecipientDetails = ({ surveyCode, recipient }) => {
  /*eslint-disable-next-line*/
  const [recipientsContactHistory, setRecipientsContactHistory] =
    useState(null);
  /*eslint-disable-next-line*/
  const [openRecipientContactHistory, setOpenRecipientContactHistory] =
    useState(false);
  const contactHistoryColumns = [
    {
      Header: "Date",
      accessor: "contactDate",
      Cell: ({ cell: value }) => {
        const formattedDate = dayjs(value.row.original.contactDate).format(
          dateTimeDisplayFormat
        );
        return value.value !== undefined ? formattedDate : "???";
      },
    },
    { Header: "Contact Method", accessor: "contactMethod" },
  ];
  const handleEdit = (row) => {
    setOpenRecipientContactHistory(true);
    setRecipientsContactHistory(row);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Recipient
            surveyCode={surveyCode}
            // uid={cache.get("uid")}
            recipient={recipient}
          ></Recipient>
        </Grid>
        <Grid item xs={12} lg={6}>
            <Table
              columns={contactHistoryColumns}
              data={recipient?.contactHistory}
              selected
              caption="Contact History"
              onSelected={(row) => handleEdit(row.original)}
            ></Table>
        </Grid>
      </Grid>
    </>
  );
};
