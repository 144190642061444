// TODO: Refactor user log in sequence
/* 
User log in process
STAGE 1: Authenticated?
  TRUE: Proceed to STAGE 2
  FALSE: Show Hero/Content components
STAGE 2: Employee?
  TRUE: Show Dashboard
  FALSE: Show There is a problem with your account component

/* eslint-disable */
import { useContext } from "react";
import { AuthContext } from "../services/context";
import Button from "../components/Button";
import * as utils from "../services/utilities";
import Dashboard from "./dashboard";
import { makeStyles, useTheme } from "@mui/styles";
import backgroundGredient from "../assets/backgroundGredient.svg";
import { HOMEPAGE_CONTENT } from "../utils/contentData";
import { Seo } from "./Seo";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import HomeHeroTopIcon from "../assets/HomeHeroTop.svg";
import HomeHeroBottomIcon from "../assets/HomeHeroBottom.svg";

const homeStyles = makeStyles((theme) => ({
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "auto",
    padding: "15px 15px 0px 0px",
    border: "1px solid #e2e8f0",
    borderRadius: "20px",
    background:
      "linear-gradient(237deg, rgba(153, 196, 85, 0.20) 0%, rgba(64, 91, 192, 0.20) 50.07%, rgba(12, 16, 152, 0.20) 100%)",
  },
  videoStyling: {
    height: "100%",
    overflowY: "auto",
  },
  // - Hero Section
  hero: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "150px 80px 40px 80px",
    margin: "0px",
    gap: "40px",
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      padding: "100px 20px 40px 20px",
    },
    "@media (max-width: 540px)": {
      padding: "100px 20px 40px 20px",
    },
  },
  heroLeft: {
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1020px)": {
      maxWidth: "100%",
    },
  },
  // - quickGlance
  quickGlance: {
    display: "flex",
    alignItems: "center",
    marginBottom: "100px",
    background:
      "linear-gradient(180deg, rgba(244, 247, 251, 0) 4.81%, #F4F7FB 81.54%)",
    "@media (max-width: 1020px)": {
      padding: "10px 20px",
    },
  },
  quickGlanceRight: {
    padding: "20px 30px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
  },
  quickGlanceRightTitle: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "38.4px",
    padding: "60px 60px 0px 60px",
    fontFamily: "Inter",
  },
  quickGlanceFeatures: {
    display: "grid",
    padding: "0px 60px 0px 60px",
    rowGap: "15px",
    gridTemplateColumns: "auto auto",
    "& div": {
      display: "flex",
      gap: "10px",
      width: "90%",
      flexDirection: "column",
    },
    "& h3": {
      fontSize: "18px",
      fontWeight: 400,
      color: "#091133",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 500,
      color: "#1B1D21",
      opacity: "80%",
    },
  },
  "@media (max-width: 1200px)": {
    quickGlanceLeft: {
      display: "none",
      padding: "10px",
    },
    quickGlanceFeatures: {
      "& img": {
        width: "40px",
      },
      "& div": {
        gap: "2px",
      },
      "& h3": {
        lineHeight: "32px",
      },
    },
    quickGlance: {
      paddingBottom: "10px",
    },
  },
  "@media (max-width:540px)": {
    quickGlanceRight: {
      padding: 0,
      "& h2": {
        padding: 0,
      },
    },
    quickGlanceFeatures: {
      padding: 0,
    },
  },
  // - features
  features: {
    display: "flex",
    padding: "60px 120px",
    gap: 100,
    alignItems: "center",
    justifyContent: "space-between",
    "& h2": {
      lineHeight: "50.4px",
    },
    "& div": {
      width: "50%",
    },
    "@media (max-width:540px)": {
      padding: "0px 20px",
    },
  },
  featuresEvenCard: {
    flexDirection: "row-reverse",
  },
  featuresOddCard: {
    flexDirection: "row",
  },

  featuresLeft: {
    width: "100%",
    display: "flex",
    background: "#F1F5F9",
    borderRadius: "20px",
    justifyContent: "center",
    "& img": {
      maxWidth: "100%",
      padding: "100px 30px",
    },
  },
  featuresRightTitle: {
    fontWeight: 700,
    lineHeight: "50.4px",
    fontSize: "42px",
  },

  "@media (max-width:1020px)": {
    features: {
      gap: "20px",
      "& div": {
        width: "100%",
      },
    },
    featuresEvenCard: {
      flexDirection: "column",
    },
    featuresOddCard: {
      flexDirection: "column",
    },
    featuresRightTitle: {
      color: "#0f172a",
      fontSize: "42px",
      lineHeight: "50px",
      fontWeight: 600,
    },
  },
  featuresRightDescription: {
    fontWeight: 400,
    fontSize: "18px",
    color: "#0f172a",
    lineHeight: "30px",
  },
  // - Pricing
  pricing: {
    height: "100%",
    width: "100%",
    background: "#FFFFFF",
  },
  pricingImage: {
    position: "relative",
    "&::before": {
      backgroundImage: `url(${backgroundGredient})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top",
      height: "73%",
      width: "100%",
      content: '""',
      bottom: "0",
      gap: "200px",
      position: "absolute",
    },
  },
  pricingPlans: {
    fontWeight: 600,
    fontSize: "42px",
    lineHeight: "60px",
    alignItems: "center",
    color: "black",
    display: "flex",
    justifyContent: "center",
    padding: "0px 70px",
  },

  "@media (max-width: 1020px)": {
    fontSize: "32px",
    lineHeight: "38px",
  },

  // New Desgin Style

  heroWrapper: {
    width: "100%",
    margin: "auto",
    position: "relative",
  },
  heroWrapperTopIcon: {
    backgroundImage: `url(${HomeHeroTopIcon})`,
    backgroundSize: "cover",
    height: "248.92px",
    width: "100px",
    position: "absolute",
    right: "0px",
    transform: "rotate(-0.9deg)",
  },
  heroWrapperBottomIcon: {
    backgroundImage: `url(${HomeHeroBottomIcon})`,
    backgroundSize: "cover",
    height: "360px",
    width: "132px",
    position: "absolute",
    left: 0,
    top: "-338px",
    transform: "rotate(0.1deg)",
  },
  heroContainer: {
    padding: "150px 0px 40px 0px",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    gap: "20px",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "150px 20px 40px 20px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "150px 40px 40px 40px",
    },
  },
  heroTitle: {
    fontWeight: 700,
    lineHeight: "75px",
    fontSize: "70px",
    textAlign: "center",
    color: "#29292B",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      lineHeight: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      lineHeight: "30px",
    },
  },
  heroDescription: {
    fontSize: "20px",
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "32px",
    padding: "0px 120px",
    color: "#0f172a",
    "& a": {
      textDecoration: "underline",
      color: "#26262A",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
  },

  buttonPrimary: {
    height: "50px",
    textTransform: "capitalize",
    borderRadius: "40px",
    padding: "8px 60px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 25px",
    },
  },

  buttonSecondary: {
    height: "50px",
    textTransform: "capitalize",
    borderRadius: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 10px",
    },
  },

  infoWrapper: {
    background: "#0D121F",
    color: "#FFFFFF",
  },
  infoContainer: {
    padding: "100px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
  },
  infoTitlePrimary: {
    color: "#0066F9",
    textAlign: "center",
  },
  infoTitleSecondary: {
    textAlign: "center",
    fontSize: "40px",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "25px",
    },
  },
  infoDescription: {
    textAlign: "center",
    width: "50%",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "17px",
    },
  },
  infoCardWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  infoCardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    height: "600px",
    width: "350px",
    color: "#FFFFFF",
    margin: "50px 0px 80px 0px",
    borderRadius: "10px",
    "@media (max-width:1020px)": {
      margin: "10px 0px",
    },
  },
  infoCardImage: {
    background: "#FFFFFF",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",

    height: "400px",
    borderRadius: "10px",
  },
  videoWraper: {
    position: "relative",
    margin: "350px 0px 0px 0px",
  },
  videoInfoWrapper: {
    background: "#0B109F",
    height: "800px",
    width: "100%",
  },
  videoInfoContainer: {
    padding: "500px 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#FFFFFF",
    gap: "20px",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },
  },
  videoButton: {
    padding: "12px 180px",
    borderRadius: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "12px 120px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px 60px",
    },
  },
  videoInfoTitle: {
    fontSize: "42px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  videoInfoDescription: {
    fontSize: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  DemoWrapper: {
    height: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#fef5d4",
    margin: "100px 0px 0px 0px",
    padding: "0px 30px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      gap: "20px",
    },
  },
  DemoContainer: {
    maxWidth: "500px",
  },
  DemoContainerTitle: {
    fontSize: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      textAlign: "center",
    },
  },
  DemoContainerDescription: {
    fontSize: "17px",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      textAlign: "center",
    },
  },
  DemoButton: {
    borderRadius: "40px",
    padding: "10px 40px",
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
}));

export default function Home({ isSideBarOpen, handleToggleSideBar }) {
  utils.log.component("Home");

  // #region Initialize
  const { employee } = useContext(AuthContext);

  if (employee) {
    return (
      <Dashboard
        isSideBarOpen={isSideBarOpen}
        handleToggleSideBar={handleToggleSideBar}
      />
    );
  }

  // const classes = homeStyles();
  return (
    <div style={{ background: "#FFFFFF", fontFamily: "DM Sans" }}>
      <div style={{ margin: "0px 0px" }}>
        <Seo
          title={"Commuter Survey Tool"}
          description={
            "The Commuter Survey Tool (CST) is a purpose built survey management system for the TDM community"
          }
        />

        <HeroSection />
        <VideoSection />
        <InfoSection />
        <HomePageFeatureList />
      </div>
      <Demo />
    </div>
  );
}

export const HomePageFeatureList = () => {
  const classes = homeStyles();
  return (
    <>
      {HOMEPAGE_CONTENT.features.map((item, index) => (
        <div
          key={item?.title}
          className={`${
            index % 2 === 0 ? classes.featuresEvenCard : classes.featuresOddCard
          } ${classes.features}`}
        >
          <div className={classes.featuresLeft}>
            <img src={item.mainImg} alt="main" height="auto" />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <h3 className={classes.featuresRightTitle}>{item.title}</h3>
            <p className={classes.featuresRightDescription}>
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

const Demo = () => {
  // const { loginWithRedirect } = useAuth0();

  const classes = homeStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.DemoWrapper}>
      <div className={classes.DemoContainer}>
        <h4 className={classes.DemoContainerTitle}>
        Ready to Revolutionise Your Commuter Surveys?
        </h4>
        <p className={classes.DemoContainerDescription}>
        Join hundreds of organisations using our tool to optimise transportation strategies.
        </p>
      </div>
      <Button variant="contained-primary" className={classes.DemoButton} onClick={() => navigate("/contact")}>
        Get in Touch
      </Button>
    </div>
  );
};


const HeroSection = () => {
  const classes = homeStyles();
  return (
    <div className={classes.heroWrapper}>
      <div className={classes.heroWrapperTopIcon}></div>
      <div className={classes.heroContainer}>
        <h2 className={classes.heroTitle}>
          Commuter <span style={{ color: "#8DC63F" }}> Survey Tool </span> That
          Works For You.
        </h2>
        <p className={classes.heroDescription}>
          The Commuter Survey Tool (CST) is a purpose built survey management
          system for the TDM community powered by{" "}
          <Link to="https://www.gopassgo.net/">GoPassGo Networks, LLC</Link>
        </p>
        <HomePageButtons />
      </div>
    </div>
  );
};

export const HomePageButtons = () => {
  const classes = homeStyles();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();
  const isSm = theme.breakpoints.down("sm");
  // const isMd = theme.breakpoints.down("md")
  return (
    <div className={classes.buttonContainer}>
      <Button
        size="medium"
        color="primary"
        variant="contained-green"
        className={classes.buttonPrimary}
        onClick={() =>
          loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
        }
      >
        {isSm ? "Start Free " : "Start For Free"}
      </Button>
      <Button
        size="medium"
        variant="contained-white"
        className={classes.buttonSecondary}
        onClick={() => navigate("/contact")}
      >
        {isSm ? "Talk with Sales" : " Get in touch with Sales"}
      </Button>
    </div>
  );
};

const VideoSection = () => {
  const classes = homeStyles();
  const {loginWithRedirect} = useAuth0();
  return (
    <div className={classes.videoWraper}>
      <div className={classes.videoInfoWrapper}>
        <div className={classes.videoInfoContainer}>
          <div className={classes.heroWrapperBottomIcon}></div>
          <h2 className={classes.videoInfoTitle}>
            Confused what suits your needs? Talk with Team
          </h2>
          <p className={classes.videoInfoDescription}>
            Follow our newsletter. We will regulary update our latest project
            and availability.
          </p>
          <Button variant="contained-white" className={classes.videoButton}
          onClick={() =>
            loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
          }
          >
            Try Free
          </Button>
        </div>
      </div>
      <div
        style={{
          boxShadow: "0px 0px 20px 0px #0000000F",
          height: "700px",
          margin: "0px auto",
          position: "absolute",
          bottom: "400px",
          left: "70px",
          right: "70px",
          background: "white",
          borderRadius: "20px",
        }}
      >
        {/* <iframe
          width="1800"
          height="687"
          borderRadius="20px"
          src="https://www.youtube.com/embed/P_HFMVojp78?autoplay=1&mute=1"
          title="Introduction to Commuter Survey Tool"
          frameBorder="0"
          autoplay
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          loop
        ></iframe> */}
      </div>
    </div>
  );
};

const InfoSection = () => {
  const classes = homeStyles();
  const navigate = useNavigate();
  const {loginWithRedirect} =useAuth0();
  return (
    <div className={classes.infoWrapper}>
      <div className={classes.infoContainer}>
        <h4 className={classes.infoTitlePrimary}>HOW IT WORKS</h4>
        <h3 className={classes.infoTitleSecondary}>
        How GoPass Commuter Survey Tool Transforms Your Survey Process?
        </h3>
        <p className={classes.infoDescription}>
        Explore how our tool streamlines survey management, improves data accuracy, and drives actionable insights easily.

        </p>
      </div>
      <div className={classes.infoCardWrapper}>
        {HOMEPAGE_CONTENT?.cards?.map((item) => {
          return (
            <div className={classes.infoCardContainer} key={item?.title}>
              <div className={classes.infoCardImage}></div>
              <h3>{item?.title}</h3>
              <p>{item?.description}</p>
            </div>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "0px 0px 150px 0px",
        }}
      >
        <Button
          variant="contained-primary"
          style={{
            padding: "10px 40px",
            borderRadius: "50px",
            fontSize: "14px",
          }}
          onClick={() =>
            loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
          }
        >
          Get a Free Demo
        </Button>
        <Button
          variant="text"
          style={{
            padding: "10px 40px",
            borderRadius: "50px",
            fontSize: "14px",
            color: "#FFFFFF",
            border: "2px solid #FFFFFF",
          }}
          onClick={() => navigate("/pricing")}
        >
          See Pricing
        </Button>
      </div>
    </div>
  );
};
