// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES:
BUG: 051323 - Crash when trying to edit a choice.
BUG: 050423 - When in Choice detail view, 
  on Delete button click, dialog box closes to Questions list.  
  Expected to close to Choices list.
handleChoiceDelete() closes 
  STEPS:
  CAUSE: 
  RESOLUTION:
  FIX:

*/
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import Button from "../../components/Button";
import Form from "../../components/Form";
import Input from "../../components/Input/inputs";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { CommentEditView as Comment } from "./comment";
import * as dependency from "./dependency";
import { Selector as DependencySelector } from "./dependencySelector";

import FormUtility from "../../services/form";
import { HeaderActions } from "../../components/HeaderActions";
import MUIDialog from "../../components/Modal";
const formUtility = new FormUtility();

export const Choices = ({
  question,
  questions,
  handleChoiceDelete,
  onDragEnd,
}) => {
  // PURPOSE: Display and manage choices for a question.
  utils.log.component(
    `Question(${question.code}) - Choices: ${JSON.stringify(question.choices)}}`
  );
  // #region Initialize

  /*eslint-disable-next-line*/
  const [add, setAdd] = useState({});
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [choice, setChoice] = useState(null);
  const columns = [
    { Header: "Code", accessor: "code" },

    { Header: "Description", accessor: "description" },
    { Header: "Value", accessor: "value" },
  ];
  // const hasChoices = question.choices?.length > 0;
  utils.log.info("question.choices", question.choices);

  const choices =
    question.choices?.length > 0 ? utils.cloneObject(question.choices) : []; // use spread to copy question.choices

  // #endregion
  // #region Events
  const handleAddOpen = () => {
    setAdd(null);
    setAddOpen(true);
  };
  const handleAddClose = () => {
    setAddOpen(false);
  };
  const handleEdit = (update) => {
    setChoice(update);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    const choiceIndex = question.choices.findIndex(
      (c) => c.code === choice.code
    );
    question.choices[choiceIndex] = choice;
    setEditOpen(false);
  };
  const handleChoiceDeletion = (questionCode, choice) => {
    utils.log.event(`Choices.handleChoiceDeletion(${questionCode}, ${choice})`);
    handleChoiceDelete(questionCode, choice);
    setEditOpen(false);
    utils.log.stateChange(
      "Choices.handleChoiceDeletion - setEditOpen(false)",
      question.choices
    );
  };

  const headerAction = <HeaderActions text={"Add"} onAdd={handleAddOpen}/>

  // #endregion
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="flex-end">

          {addOpen && (
            <Add
              open={addOpen}
              onClose={handleAddClose}
              list={question.choices}
              questions={questions}
            ></Add>
          )}

          {editOpen && (
            <Details
              open={editOpen}
              onClose={handleEditClose}
              choice={choice}
              setChoice={setChoice}
              question={question}
              questions={questions}
              handleChoiceDelete={handleChoiceDeletion}
            ></Details>
          )}
        </Grid>
        {/* {hasChoices && ( */}
          <Grid item xs={12}>
            <Table
              columns={columns}
              data={choices}
              hideFilter
              hidePagination
              selected
              onSelected={(row) => handleEdit(row.original)}
              isDraggable
              onDragEnd={(result) => onDragEnd(result.map((r) => r.original))}
              headerAction={headerAction}
            ></Table>
          </Grid>
        {/* )} */}
        <Grid item xs={12}>
          <Comment question={question}></Comment>
        </Grid>
      </Grid>
    </>
  );
};

const Details = ({
  onClose,
  open,
  choice,
  question,
  questions,
  handleChoiceDelete,
}) => {
  // PURPOSE: Display and manage a specific choice details for a question.
  // #region Functions
  const isValid = () => {
    // PURPOSE: Validate choice.
    error.clearErrorObject("code");
    if (!choice.code) {
      // Require cache.get("code") has a value
      error.setErrorObject("code", true, "Code is required.");
      setCodeErrorObject(error.getErrorObject("code"));
    }

    if (
      choice.code !== choiceOriginal.code && // Code changed.
      !utils.isUniqueValue(choice.code, choiceCodes) // Code already used.
    ) {
      // Code already used.
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject(error.getErrorObject("code"));
    }
    /*     else {
      error.clearErrorObject("code");
    } */
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };
  // #endregion
  // #region Initialize
  utils.log.component("Question(Choice.Details) component");
  const { error } = useContext(AuthContext);
  const choiceOriginal = utils.cloneObject(choice);
  const [codeErrorObject, setCodeErrorObject] = useState(null);
  /*eslint-disable-next-line*/
  const [valueErrorObject, setValueErrorObject] = useState(null);

  const choiceCodes = question.choices
    .map((c) => c.code)
    .filter((c) => c.code !== choice.code); // Exclude current choice.

  useEffect(() => {
    utils.log.useEffect("choices.js - Details.Init - choice", choice);
    //cache.clear();
  }, []);
  // #endregion
  // #region Events
  const handleUpdate = (event, question, choice, choiceOriginal) => {
    event.preventDefault();

    if (isValid()) {
      utils.log.info("choices.js - Details.handleUpdate(valid)");
      //setChoice(choice);
      onClose();
    } else {
      console.error("choices.js - Details.handleUpdate(invalid)");
      utils.log.error("Validation errors", error.getErrors());
    }
  };

  const handleDelete = () => {
    handleChoiceDelete(question.code, choice);
  };
  // #endregion
  return (
    <MUIDialog
    open={open} 
    onClose={onClose}
    // fullWidth
    title="Update Choice"
    description="Choice instructions..."
    actions={
      <>
       <Button onClick={handleDelete}>Delete</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={(e) => handleUpdate(e, question, choice, choiceOriginal)}
          >
            Update
          </Button>
      </>
    }
    >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Input
              required
              label="Code"
              fullWidth
              name="code"
              onChange={(e) => (choice.code = e.target.value)}
              defaultValue={choice.code}
              error={codeErrorObject?.state}
              helperText={codeErrorObject?.message}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Input
              required
              label="Value"
              fullWidth
              name="value"
              onChange={(e) => (choice.value = e.target.value)}
              defaultValue={choice.value}
              error={valueErrorObject?.state}
              helperText={valueErrorObject?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Description"
              fullWidth
              name="description"
              onChange={(e) => (choice.description = e.target.value)}
              defaultValue={choice.description}
              error={valueErrorObject?.state}
              helperText={valueErrorObject?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <DependencySelector
              context={dependency.create(question, choice)}
              questions={questions}
            ></DependencySelector>
          </Grid>
        </Grid>
    </MUIDialog>
  );
};

const Add = ({ onClose, open, list, questions }) => {
  utils.log.component("Question(Choice.Add)");
  // #region Initialize
  const { surveyService, error } = useContext(AuthContext);
  const [inputs, setInputs] = useState({});
  const [codeErrorObject, setCodeErrorObject] = useState(null);

  /*   const [choice, setChoice] = useState({
    type: "GenericChoice",
    code: inputs.code,
    description: inputs.description,
    value: inputs.value,
  });  */
  const [choice, setChoice] = useState(
    surveyService.newChoice(inputs.code, inputs.value, inputs.description)
  );

  formUtility.setDetail(null);
  formUtility.setInputs(inputs);

  const isValid = () => {
    const isCodeAvailable = list.find((data) => data.code === formUtility.getValue("code"));
    if (isCodeAvailable) {
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject(error.getErrorObject("code"));
    } else {
      error.clearErrorObject("code");
    }
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };

  // #endregion
  // #region Events
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid()) {
      choice.code = formUtility.getValue("code");
      choice.description = formUtility.getValue("description");
      choice.value = formUtility.getValue("value");
      setChoice(choice);
      list.push(choice);
      onClose();
    }
  };
  // #endregion
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>Add Choice</DialogTitle>
      <Form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <DialogContent>
          <DialogContentText>Choice instructions...</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Input
                label="Code"
                fullWidth
                name="code"
                onChange={(e) => formUtility.handleChange(e, setInputs)}
                value={formUtility.getValue("code")}
                required
                error={codeErrorObject?.state}
                helperText={codeErrorObject?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label="Value"
                fullWidth
                name="value"
                onChange={(e) => formUtility.handleChange(e, setInputs)}
                value={formUtility.getValue("value")}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Description"
                fullWidth
                name="description"
                onChange={(e) => formUtility.handleChange(e, setInputs)}
                value={formUtility.getValue("description")}
              />
            </Grid>
            {/*             <Grid item xs={12}>
              <DependencySelector questions={questions}></DependencySelector>
            </Grid> */}
          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export function checkedCount(choices) {
  // PURPOSE: Returns the number of choices that are checked.
  const count = choices.filter((choice) => choice.isSelected).length;
  return count;
}
export const valueFormatted = (choice, delimeter = "|") => {
  // PURPOSE: Returned value for use in radio/checkbox/dropdown input.
  const value = `${choice.question().code}${delimeter}${choice.code}`;
  return value;
};
export default Choices;

/* 
FIXED
040723 - Editing choice causes crash.
    CAUSE(040723): 
      * Details component is calling DependencySelector with the wrong parameter name (current instead of context).
    FIX(040723): 
      * Changed parameter from current to context.

040723 - Adding/updating choice, closes dialog to questions list.  Need to only close to the choices list.
    CAUSE(040723): Form component  onSubmit was creating unintended behavior.
    FIX(040723): Use Button component onClick event handler instead to call the same function.    
*/
