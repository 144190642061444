// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
import { useContext, useState } from "react";
import Button from "../../components/Button";
import Popup from "../../components/Form/Popup";
import Grid from "../../components/Grid";
import Input from "../../components/Input/inputs";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import { PlanSelector } from "./plans";

export default function Ordering({ open, onClose, error, setError }) {
  const { employee } = useContext(AuthContext);

  const [selectedPlan, setSelectedPlan] = useState(null);
  //useEffect(() => {}, [selectedPlan]);
  const handleSelected = (selectedPlan) => {
    setSelectedPlan(selectedPlan);
    setOptionsOrdering((previous) => {
      return {
        ...previous,
        dialogActions: (
          <Button onClick={handleSubscribe(selectedPlan, employee)}>
            NextB
          </Button>
        ),
      };
    });
  };

  const handleSubscribe = () => {
    //planService.subscribe(selectedPlan, [employee.email]);
    // TODO: Popup: OrderPreview to eliminate already existing subscribers.
    //setOpenPreview(true);
  };
  // *** Ordering ***
  const [optionsOrdering, setOptionsOrdering] = useState({
    open: open,
    onClose: onClose,
    error: error,
    setError: setError,

    dialogTitle: "Select subscription plan(s)",
    dialogContentText: "",
    dialogActions: <Button disabled={selectedPlan == null}>NextA</Button>,
    gridItems: (
      <OrderingGridComponent
        selectedPlan={selectedPlan}
        handleSelected={handleSelected}
      />
    ),
  });

  return (
    <>
      <Popup options={optionsOrdering}></Popup>
    </>
  );
}

const OrderingGridComponent = ({ selectedPlan, handleSelected }) => {
  const [openSubscriberAdd, setOpenSubscriberAdd] = useState(false);
  const [plan, setPlan] = useState([]);
  /*eslint-disable-next-line*/
  const [subscribersMonthly, setSubscribersMonthly] = useState([]);
  /*eslint-disable-next-line*/
  const [subscribersAnnualy, setSubscribersAnnually] = useState([]);
  const handleClose = (e) => {
    e.preventDefault();

    setOpenSubscriberAdd(false);
  };
  const handleSubscriberAddClick = (plan) => {
    setPlan(plan);
    setOpenSubscriberAdd(true);
  };
  const handleSubscribers = () => {
    debugger;
  };
  const getSubscriberList = (plan) => {
    switch (plan) {
      case "monthly":
        return setSubscribersMonthly;

      case "annualy":
        return setSubscribersAnnually;
      default:
        alert(`Unsupported plan: ${plan}`);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <h3>Select your subscription plan.</h3>
      </Grid>
      <Grid item xs={12}>
        <PlanSelector
          selectedPlan={selectedPlan}
          handleSelected={handleSelected}
        ></PlanSelector>
      </Grid>

      <Grid item xs={12}>
        <h3>Additional user subscriptions</h3>
      </Grid>

      <Grid item xs={10}>
        Monthly plan $9.99/month each
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => handleSubscriberAddClick("monthly")}>Add</Button>
      </Grid>

      <Grid item xs={10}>
        Annual plan $100/year each
      </Grid>
      <Grid item xs={2}>
        <Button>Add</Button>
      </Grid>
      <OrderPreview></OrderPreview>
      {openSubscriberAdd && (
        <SubscriberAddDialog
          open={openSubscriberAdd}
          onClose={handleClose}
          plan={plan}
          subscribers={getSubscriberList(plan)}
          handleSubscribers={handleSubscribers}
        ></SubscriberAddDialog>
      )}
    </>
  );
};

const SubscriberAddDialog = ({
  subscribers,
  plan,
  handleSubscribers,
  open,
  onClose,
  error,
  setError,
}) => {
  // #region Assertions
  // #endregion Assertions
  // #region Events
  const handleInputChangeEmail = (e) => {
    //const uid = formUtility.getInputText("users", e.target).value;
    setInputValueEmail(e.target.value);
  };
  const handleSubmit = () => {};
  // #endregion
  // #region Initialize
  /*eslint-disable-next-line*/
  const [inputValueEmail, setInputValueEmail] = useState("");
  const [options] = useState({
    open: open,
    onClose: onClose,
    error: error,
    setError: setError,

    dialogTitle: `Add users for subscription plan: ${plan}`,
    dialogContentText: "",
    dialogActions: (
      /*       <Form onSubmit={handleAddUsers}>
        <Button type="submit">Add</Button>
      </Form> */
      <Button onClick={handleSubmit}>Add</Button>
    ),
    gridItems: (
      <Grid item xs={12}>
        <Input
          fullWidth
          multiline
          label="Users to subscribe"
          name="users"
          onChange={(e) => handleInputChangeEmail(e)}
        ></Input>
      </Grid>
    ),
  });
  // #endregion

  return (
    <>
      <Popup options={options}></Popup>
    </>
  );
};

export const SubscribeUsers = () => {
  debugger;
  return <h1>Subscribe users</h1>;
};

const OrderPreview = () => {
  return (
    <>
      <Grid item xs={12}>
        <Table columns={[]} data={[]} fullWidth></Table>
      </Grid>
    </>
  );
};
